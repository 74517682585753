import React from 'react';
import { ArrowRight, ArrowLeft } from 'react-feather';

import { createNamedStyled, keyframes } from '../../stitches.config';
import { useTheme, useColorOverrides } from '../../theme';

import { useLanguage } from '../../context/Language';

import { Label } from './Text';
import Notification from './Notification';

import Link from '../Patterns/Link';

const styled = createNamedStyled('Button');

const Base = styled.named('Base')('div', {
  position: 'relative',
  pointerEvents: 'auto',
  cursor: 'pointer',
  width: 'fit-content',

  padding: '$style$buttonSize',
  borderRadius: '$s',

  backfaceVisibility: 'hidden',
  transformStyle: 'preserve-3d',
  perspective: '1000px',

  [['&:before', '&:after']]: {
    content: '',
    position: 'absolute',
    inset: 0,
    width: '100%',
    height: '100%',
    borderRadius: '$s',
  },

  [Label]: { transition: 'color 300ms' },

  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  variants: {
    round: {
      true: {
        padding: '0 $s',
        aspectRatio: '1 / 1',
      },
    },
  },
});

const Icon = styled.named('Icon')('div', {
  zIndex: 1,
  marginInlineStart: -16,
  opacity: 0,

  '*': { stroke: '$buttonForeground' },
});

const Background = styled.named('Background')('div', {
  position: 'absolute',
  inset: 0,
  width: '100%',
  height: '100%',
  borderRadius: '$s',
});

const KEYFRAMES = {
  moveUpInitial: keyframes({
    '100%': { transform: 'translate(0, -105%)' },
  }),

  moveUpEnd: keyframes({
    '0%': { transform: 'translate(0, 100%)' },
    '100%': { transform: 'translate(0, 0)' },
  }),
};

const STYLES = {
  // FADE
  fade: {
    '&:before': {
      background: '$buttonBackground',
      transition: 'opacity $m',
    },

    '&:hover': {
      '&:before': { opacity: 0.8 },
    },
  },

  // ACCENT
  accent: {
    [['&:before', '&:after']]: {
      transition: 'transform $m $ease',
    },

    '&::before': {
      background: '$buttonBackground',
    },

    '&:hover::before': {
      transform: 'scale(0.9, 0.8)',

      '.round&': {
        transform: 'scale(0.8, 0.8)',
      },
    },

    '&::after': {
      border: '1px solid $buttonBackground',
      transform: 'scale(0.85, 0.65)',

      '.round&': {
        transform: 'scale(0.75, 0.75)',
      },
    },

    '&:hover::after': {
      transform: 'scale(1)',
    },
  },
  // PULSE
  pulse: {
    [Background]: {
      background: '$buttonBackground',
      overflow: 'hidden',
      transition: 'transform $m $ease',

      [['&::before', '&::after']]: {
        content: '',
        position: 'absolute',
        background: '$shade100',
      },

      '&::before': {
        width: '110%',
        height: 0,
        paddingBottom: '110%',
        top: '50%',
        left: '50%',
        borderRadius: '50%',
        transform: 'translate(-50%,-50%) scale(0)',
      },

      '&::after': {
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        opacity: 0,
        transition: 'opacity $m',
      },
    },

    [Label]: { transition: 'color $l' },

    '&:hover': {
      [Background]: {
        transform: 'scale(1, 1.4)',

        '&::before': {
          transform: 'translate(-50%,-50%) scale(1)',
          transition: 'transform $m $ease',
        },

        '&::after': {
          opacity: 1,
          transition: 'opacity 10ms $m',
        },
      },

      [Label]: { color: '$tint100' },
    },
  },
  // REVEAL
  reveal: {
    background: '$shade100',

    '&::before': {
      background: '$buttonBackground',
      transformOrigin: '50% 100%',
      clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0% 100%)',
      transition: 'clip-path $m $ease',
    },

    '&:hover': {
      '&::before': {
        transition: 'clip-path $m $ease',
        clipPath: 'polygon(0 0, 100% 0, 0 0, 0% 100%)',
      },

      [Label]: {
        transition: 'color $s $s',
        color: '$tint100',
        animation: [
          `${KEYFRAMES.moveUpInitial}
            $transitions$s forwards $transitions$ease`,
          `${KEYFRAMES.moveUpEnd}
            $transitions$s forwards $transitions$s $transitions$ease`,
        ].join(', '),
      },
    },
  },
  // FILL
  fill: {
    border: '2px solid $buttonBackground',
    overflow: 'hidden',

    '&::before': {
      content: '',
      background: '$buttonBackground',
      transform: 'translate(0, 102%)',
      transition: 'transform $m $ease',
      borderRadius: 0,
    },

    '&:not(:hover)': {
      [Label]: { color: '$buttonBackground' },
    },

    '&:hover': {
      '&::before': {
        transform: 'translate(0)',
      },
    },

    '&.alt': {
      border: '2px solid $light',
      transition: 'border 300ms',
      [Label]: { color: '$light' },
      '&:hover': {
        border: '2px solid $buttonBackground',
        [Label]: { color: '$light' },
      },
    },
  },
  // SWIPE
  swipe: {
    transition: 'border 300ms',
    border: '1px solid $buttonBackground',
    overflow: 'hidden',

    '&::before': {
      content: '',
      top: -2,
      bottom: -2,
      height: 'calc(100% + 4px)',
      background: '$buttonBackground',
      transition: 'transform $m $ease',
      transformOrigin: '100% 50%',
      borderRadius: 0,
    },

    '&:hover': {
      '&::before': {
        transform: 'scale(0, 1)',
        transformOrigin: '0% 50%',
      },

      [Label]: {
        color: '$buttonBackground',

        animation: [
          'MoveUpInitial $transitions$m forwards $ease',
          'MoveUpEnd $transitions$m forwards $transitions$m',
        ].join(', '),
      },
    },

    '&.alt': {
      '&:hover': {
        border: '1px solid $light',
        [Label]: { color: '$light' },
      },
    },
  },
  // PRESS
  press: {
    [[Label, '&:after']]: {
      transition: 'transform $m $ease',
    },

    '&:before': {
      background: '$buttonBackgroundDark',
      opacity: 0.8,
      transform: 'translate(6px, 6px)',
      zIndex: -1,
    },

    '&:after': {
      background: '$buttonBackground',
      zIndex: -1,
    },

    '&:hover': {
      [[Label, '&:after']]: {
        transform: 'translate(-4px, -4px)',
      },
    },

    '&:active': {
      [[Label, '&:after']]: {
        transform: 'translate(4px, 4px)',
        transition: 'transform $s $ease',
      },
    },
  },
  // OVAL
  oval: {
    width: 175,
    height: 120,

    '&:before': {
      height: '90%',
      borderRadius: '58% 42% 55% 45% / 56% 45% 55% 44%',
      transition: 'transform $m $ease',
      background: '$buttonBackground',
      transform: 'rotate(10deg)',
      zIndex: -1,
    },

    '&:after': {
      width: '97%',
      height: '86%',
      borderRadius: '58% 42% 55% 45% / 56% 45% 55% 44%',
      border: '1px solid $buttonBackground',
      transform: 'rotate(-20deg)',
      transition: 'transform $m $ease',
    },

    '&:hover': {
      '&:before': {
        transform: 'rotate(30deg)',
        opacity: 1,
      },

      '&:after': {
        transform: 'rotate(-10deg) translate3d(0,-5px,0)',
      },
    },
  },
  // ICON
  icon: {
    '[data-pcn="ButtonIcon"], [data-pcn="TextLabel"]': {
      transition: 'transform $m $ease, opacity $xs',
    },

    '&:before': {
      background: '$buttonBackground',
    },

    '&:hover': {
      '[data-pcn="TextLabel"]': {
        transform: 'translateX(-12px)',
        '.rtl&': {
          transform: 'translateX(12px)',
        },
      },

      '[data-pcn="ButtonIcon"]': {
        opacity: 1,

        transform: 'translateX(12px)',
        '.rtl&': {
          transform: 'translateX(-12px)',
        },
      },
    },
  },
};

function Button({
  children, disabled, alt, className, to, css, notification, round, ...props
}) {
  const { custom: { buttonStyle } } = useTheme();
  const colorOverrides = useColorOverrides('button');

  const { direction } = useLanguage();

  return (
    <Base
      as={to ? Link : 'button'}
      to={to}
      {...props}
      css={{
        ...css,
        ...STYLES[buttonStyle],
        ...disabled && {
          pointerEvents: 'none',
          opacity: 0.5,
        },
      }}
      className={`
        ${!!alt && 'alt'}
        ${!!round && 'round'}
        ${className || ''}
        ${colorOverrides}
        ${direction === 'RTL' ? 'rtl' : 'ltr'}
      `}
      round={round}
    >
      <Background />

      <Label
        css={{
          position: 'relative',
          textAlign: 'center',
          color: '$buttonForeground',
        }}
      >
        {children}
      </Label>
      {buttonStyle === 'icon' && direction === 'RTL'
        ? <Icon as={ArrowLeft} size={16} /> : null}
      {buttonStyle === 'icon' && direction === 'LTR'
        ? <Icon as={ArrowRight} size={16} /> : null}
      {!!notification && <Notification value={notification} />}
    </Base>
  );
}

export default Button;
