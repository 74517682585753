import React from 'react';
import { createNamedStyled, keyframes } from '../../stitches.config';

import Link from '../Patterns/Link';
import { Title, Paragraph, Label } from './Text';
import PriceWithCurrency from '../../helpers/PriceWithCurrency';

import { useDictionary } from '../../context/Language';
import { useStore } from '../../context/Store'

const styled = createNamedStyled('Card');

const Wrapper = styled.named('Wrapper')('div', {
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  flex: '1 0 auto',
  width: '100%',

  transition: 'translate $m $ease',
  '&:hover': { translate: '0 -3%' },

  variants: {
    disabled: {
      true: {
        // pointerEvents: 'none',
        opacity: 0.5,
      },
    },
  },
});

const ImageWrapper = styled.named('ImageWrapper')('div', {
  position: 'relative',
  width: '100%',
  borderRadius: '$l',
  overflow: 'hidden',

  aspectRatio: '$style$imageAspectRatio',

  minHeight: 240,
  '@supports (aspect-ratio: 1/1)': {
    minHeight: 'initial',
  },

  '&:after': {
    content: '',
    position: 'absolute',
    inset: 0,
    background: '$cover',
    opacity: 0.5,
  },
});

const Content = styled.named('Content')('div', {
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  padding: '$s $xs',
  width: '100%',
});

const PriceWrapper = styled.named('PriceWrapper')('div', {
  display: 'flex',
  alignItems: 'baseline',
  gap: 6,
});

const PriceBeforeSale = styled.named('PriceBeforeSale')('span', {
  fontSize: '75%',
  textDecoration: 'line-through',
  opacity: 0.5,
});

const Tags = styled.named('Tags')('div', {
  display: 'flex',
  flexDirection: 'column',
  position: 'absolute',
  zIndex: 1,
  top: 10,
  zoom: 0.7,
  gap: 4,
  insetInlineStart: -8,

  '@desktop+': {
    zoom: 0.9,
    top: 10,
  },
});

const Tag = styled.named('Tag')(Label, {
  display: 'flex',
  width: 'fit-content',
  borderRadius: '$s',
  background: '$buttonBackground',
  color: '$buttonForeground',
  padding: 10,

  variants: {
    disabled: {
      true: {
        filter: 'grayscale(100%)',
      },
    },
  },
});

const Header = styled.named('Header')('div', {
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  gap: '$xs',
});

const RatingWrapper = styled.named('RatingWrapper')('div', {
  display: 'flex',
  flexDirection: 'row',
  gap: '5px',
  alignItems: 'center',
  marginTop: '-10px',
  '*': { whiteSpace: 'nowrap' },
});

const Rating = styled.named('Rating')('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
  zoom: 0.6,
});

const RatingIcon = styled.named('RatingIcon')('i', {
  color: '$brandColor',
});

const pulse = keyframes({
  '0%': { opacity: 0.1 },
  '50%': { opacity: 0.3 },
  '100%': { opacity: 0.1 },
})

const Skeleton = styled.named('Skeleton')('div', {
  backgroundColor: '$brandColor',
  borderRadius: '$s',
  opacity: 0.1,
  animation: `${pulse} 2s infinite`,
});

const SkeletonImage = styled.named('SkeletonImage')(Skeleton, {
  width: '100%',
  height: '100%',
});

const SkeletonTitle = styled.named('SkeletonTitle')(Skeleton, {
  width: '60%',
  height: '20px',
  marginBottom: '8px',
});

const SkeletonPrice = styled.named('SkeletonPrice')(Skeleton, {
  width: '30%',
  height: '20px',
  marginBottom: '20px',
});

const Card = ({ product: {
  name,
  image,
  price,
  priceBeforeSale,
  slug,
  inStock,
  featured,
  rating,
  reviewCount,
  loading,
} }) => {
  const { tagFeatured, tagSale, tagSoldOut } = useDictionary();
  const { data: store } = useStore();

  return (
    <Wrapper as={Link} to={`shop/${slug}`} disabled={!inStock}>
      {!loading && (
        <Tags>
        { priceBeforeSale > 0 ? <Tag>{tagSale}</Tag> : null }
        { featured ? <Tag style={{ opacity: 0.7 }}>{tagFeatured}</Tag> : null }
        { !inStock ? <Tag disabled>{tagSoldOut}</Tag> : null }
        </Tags>
      )}
      <ImageWrapper
        css={{ ...!loading
          && { background: `url(${image?.src}) no-repeat center / cover` },
        }}
      >
        {loading && <SkeletonImage />}
      </ImageWrapper>
      <Content>
        <Header>
          {loading ? <SkeletonTitle /> : <Title size="xs">{name}</Title>}
          {!loading
          && (store.reviewShowRatingOnProductCard && reviewCount > 0)
          && (
            <RatingWrapper>
              <Rating>
                <Paragraph css={{ fontSize: '22px' }}>
                  {rating.toFixed(1)}
                </Paragraph>
              </Rating>
              <RatingIcon className="ri-star-line" />
            </RatingWrapper>
          )}
        </Header>
        <Paragraph as="div">
          {loading ? <SkeletonPrice /> : (
            <PriceWrapper>
              <PriceWithCurrency value={price} product />
              {priceBeforeSale > 0 && (
                <PriceBeforeSale>
                  <PriceWithCurrency value={priceBeforeSale} product />
                </PriceBeforeSale>
              )}
            </PriceWrapper>
          )}
        </Paragraph>
      </Content>
    </Wrapper>
  );
};

export default Card;
