import getKey from 'lodash/get';

import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useStore } from '../context/Store';
import { useLanguage } from '../context/Language';

import Types from '../modules/types';

const VARIATION_SEARCH_KEYS = [
  'name',
  'storeSku',
];

function InfiniteScroll({
  loadMore,
  isLoading,
  hasMore,
  data = [],
  itemElement,
  itemsPerLoad = 12,
  queryProps,
  wrapperElement: WrapperElement = 'div',
  emptyElement: EmptyElement = 'div',
  storageKey,
}) {
  const wrapperRef = useRef(null);
  const observerRef = useRef(null);
  const previousQueryPropsRef = useRef({});
  const history = useHistory();

  const { data: store } = useStore();

  const dataKey = `${storageKey}-data`;
  const scrollKey = `${storageKey}-scrollPosition`;

  const [sessionData, setSessionData] = useState({
    data: [],
    scrollPosition: 0,
  });

  const searchRegex = useMemo(
    () => (
        queryProps.search?.length
      ? new RegExp(Types.getSafeSearchRegex(queryProps.search), 'ig')
      : null
    ),
    [queryProps.search],
  );

  useEffect(() => {
    previousQueryPropsRef.current = queryProps;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const handleUnload = () => {
      sessionStorage.removeItem(dataKey);
      sessionStorage.removeItem(scrollKey);
    };

    window.addEventListener('beforeunload', handleUnload);

    return () => {
      window.removeEventListener('beforeunload', handleUnload);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { language } = useLanguage();

  useEffect(() => {
    const prevProps = previousQueryPropsRef.current;
    const hasChanged = (
      prevProps.search !== queryProps.search
      || prevProps.sort !== queryProps.sort
      || prevProps.categoryId !== queryProps.categoryId
      || prevProps.subcategoryId !== queryProps.subcategoryId
      || prevProps.collectionId !== queryProps.collectionId
      || prevProps.isOnSale !== queryProps.isOnSale
      || prevProps.isFeatured !== queryProps.isFeatured
      || prevProps.language !== language
    );

    if (hasChanged) {
      sessionStorage.removeItem(dataKey);
      sessionStorage.removeItem(scrollKey);
      setSessionData({ data: [], scrollPosition: 0 });
      loadMore();
      previousQueryPropsRef.current = { ...queryProps, language };
      // wrapperRef.current.scrollIntoView({ behavior: 'instant' });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryProps, language]);

  useEffect(() => {
    if (history.action === 'POP') {
      const savedData = sessionStorage.getItem(dataKey);
      const scrollPosition = parseInt(
        sessionStorage.getItem(scrollKey), 10
      ) || 0;
      if (savedData) {
        setSessionData({ data: JSON.parse(savedData), scrollPosition });
      }
    } else {
      if (!sessionData.data.length) {
        loadMore();
        // TODO: Implement this
        // loadMore(sessionData.data.length / itemsPerLoad);
      }
      sessionStorage.removeItem(dataKey);
      sessionStorage.removeItem(scrollKey);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (data.length > sessionData.data.length) {
      setSessionData(prevState => ({ ...prevState, data }));

      const dataOptimized = data.map(item => ({
        _id: item._id,
        priceHighestFormatted: item.priceHighestFormatted,
        priceLowestFormatted: item.priceLowestFormatted,
        priceHighest: item.priceHighest,
        priceLowest: item.priceLowest,
        price: item.price,
        priceBeforeSale: item.priceBeforeSale,
        stockAggregates: item.stockAggregates,
        name: item.name,
        slug: item.slug,
        variationDefaults: item.variationDefaults,
        tagline: item.tagline,
        inStock: item.inStock,
        image: item.gallery?.[0],
        rating: item.rating,
        reviewCount: item.reviewCount,
      }));

      sessionStorage.setItem(dataKey, JSON.stringify(dataOptimized));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const renderedChildren = (sessionData.data || [])
    .map((item, index) => itemElement(item, index, queryProps));

  useEffect(() => {
    window.scrollTo(0, sessionData.scrollPosition);
  }, [sessionData.scrollPosition]);

  useEffect(() => {
    if (isLoading || !hasMore) return undefined;

    const element = observerRef.current;
    const handleScroll = () => {
      sessionStorage.setItem(scrollKey, window.scrollY.toString());
    };
    window.addEventListener('scroll', handleScroll);

    const observer = new IntersectionObserver(
      entries => {
        if (
          entries[0].isIntersecting
          && sessionData.data.length % itemsPerLoad === 0
        ) {
          loadMore();
          // TODO: Implement this
          // loadMore(sessionData.data.length / itemsPerLoad);
        }
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 1.0,
      }
    );
    if (element) {
      observer.observe(element);
    }

    return () => {
      if (observer && element) {
        observer.unobserve(element);
      }
      window.removeEventListener('scroll', handleScroll);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, hasMore, sessionData.data.length]);

  return (
    <WrapperElement ref={wrapperRef} style={{ position: 'relative' }}>
      {renderedChildren}
      {!isLoading && !renderedChildren.length && (
        <EmptyElement />
      )}
      {hasMore && (
        <div
          ref={observerRef}
          style={{
            position: 'absolute',
            height: '200vh',
            bottom: 0,
          }}
        />
      )}
    </WrapperElement>
  );
}

export default InfiniteScroll;
