/* eslint-disable max-len */
import React, { useEffect, useState, useCallback } from 'react';
import useEmblaCarousel from 'embla-carousel-react';
import { WheelGesturesPlugin } from 'embla-carousel-wheel-gestures';
import Autoplay from 'embla-carousel-autoplay';
import useMedia from 'use-media';

import { createNamedStyled } from '../../stitches.config';
import { useTheme, useColorOverrides } from '../../theme';

import { Title, Label } from '../Elements/Text';
import Button from '../Elements/Button';
import StoreDefinedLink from '../../helpers/StoreDefinedLink';

const ASPECT_RATIOS = {
  desktop: {
    small: '25%',
    medium: '35%',
    large: '45%',
  },
  mobile: {
    small: '75%',
    medium: '100%',
    large: '120%',
  },
};

const styled = createNamedStyled('LegacySlider');

const Container = styled.named('Container')('div', {
  width: '100%',
  maxWidth: '$siteWidth',
  margin: '0 auto',
  overflow: 'hidden',
});

const Wrapper = styled.named('Wrapper')('div', {
  position: 'relative',
  overflow: 'hidden',

  margin: '$s -$s',
  padding: '0 calc(2 * $s)',

  '@desktop+': {
    margin: '$m -$m',
    padding: '0 calc(2 * $m)',
  },

  '@mobile': {
    overflow: 'hidden',
  },

  '@tablet-': {
    '& > *': { zoom: 0.8 },
  },

  variants: {
    overflow: {
      false: {
        overflow: 'hidden',
        margin: '0 !important',
        padding: '0 !important',
        borderRadius: '$l',

        '@desktop-': {
          borderRadius: 0,
        },
      },
    },
    draggable: {
      true: {
        cursor: 'grab !important',
        '&:active': {
          cursor: 'grabbing !important',
        },
      },
    },
  },
});

const Track = styled.named('Track')('div', {
  position: 'relative',
  display: 'flex',

  variants: {
    overflow: {
      true: {
        gap: 15,
        '@mobile-': { gap: 10 },
      },
    },
  },
});

const Slide = styled.named('Slide')('div', {
  position: 'relative',
  width: '100%',
  flex: '0 0 auto',
  overflow: 'hidden',
  cursor: 'inherit !important',
  maskImage: 'url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC")',

  variants: {
    overflow: {
      true: {
        borderRadius: '$l',
      },
    },
  },
});

const Shade = styled.named('Shade')('div', {
  position: 'absolute',
  width: '100%',
  height: '100%',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  background: '$shade',
});

const SlideItemWrapper = styled.named('SlideItemWrapper')('div', {
  position: 'relative',
});

const SlideItem = styled.named('SlideItem')('img', {
  position: 'absolute',
  top: 0,
  bottom: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  objectPosition: 'center',

  variants: {
    parallax: {
      true: {
        width: '120%',
        left: '-10%',

        '@wide+': {
          width: '165%',
          left: '-32.5%',
        },
      },
    },
  },
});

const Content = styled.named('Content')('div', {
  position: 'absolute',
  bottom: 0,
  display: 'flex',
  flexDirection: 'column',
  padding: '30px',
  gap: 5,

  variants: {
    rtl: {
      true: {
        right: 0,
      },
      false: {
        left: 0,
      },
    },
  },

  defaultVariants: {
    rtl: false,
  },
});

const Fade = styled.named('Fade')('div', {
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  background:
    'linear-gradient(to top, hsla(0, 0%, 10%, 0.5) 10%, hsla(0, 0%, 10%, 0) 60%)',
});

const Slider = ({
  id,
  size,
  autoplay,
  overflow,
  slides,
  // eslint-disable-next-line no-unused-vars
  ...props
}) => {
  const { custom: { parallax }, language } = useTheme();

  const isMobile = useMedia({ maxWidth: 1024 });

  const multipleItems = slides && (slides.length > 1);

  const autoplayOptions = Autoplay({
    stopOnLastSnap: overflow,
    stopOnInteraction: true,
    delay: 5000,
  });

  const wheelGestures = WheelGesturesPlugin();

  const [emblaRef, embla] = useEmblaCarousel({
    containScroll: 'trimSnaps',
    skipSnaps: true,
    draggable: multipleItems,
    align: 'center',
    loop: !overflow,
    direction: language.direction,
  }, autoplay ? [autoplayOptions, wheelGestures] : [wheelGestures]);

  const [parallaxValues, setParallaxValues] = useState([]);

  useEffect(() => {
    if (!embla) return;
    embla.reInit();
  }, [embla]);

  const onScroll = useCallback(() => {
    if (!embla) return;

    const engine = embla.internalEngine();
    const scrollProgress = embla.scrollProgress();

    const styles = embla.scrollSnapList().map((scrollSnap, index) => {
      let diffToTarget = scrollSnap - scrollProgress;

      if (engine.options.loop) {
        engine.slideLooper.loopPoints.forEach((loopItem) => {
          const target = loopItem?.getTarget?.();
          if (index === loopItem.index && target !== 0) {
            const sign = Math.sign(target);
            if (sign === -1) diffToTarget = scrollSnap - (1 + scrollProgress);
            if (sign === 1) diffToTarget = scrollSnap + (1 - scrollProgress);
          }
        });
      }

      return diffToTarget * (-1 / parallax) * 100;
    });

    setParallaxValues(styles);
  }, [embla, parallax]);

  useEffect(() => {
    if (!embla) return;

    onScroll();

    embla.on('scroll', onScroll);
    embla.on('resize', onScroll);
  }, [embla, onScroll, parallax]);

  const parallaxDirection = language.direction === 'rtl' ? -1 : 1;

  const colorOverrides = useColorOverrides('slider');

  return (
    <Container id={id}>
      <Wrapper
        ref={emblaRef}
        overflow={overflow}
        draggable={multipleItems}
        className={colorOverrides}
      >
        <Track overflow={overflow}>
          {slides.map((slide, index) => (
            <Slide
              key={slide._id}
              overflow={overflow}
              as={slide.contentButtonLink ? StoreDefinedLink : 'div'}
              to={slide.contentButtonLink}
            >
              <Shade />
              <SlideItemWrapper
                size={size}
                image={isMobile
                  ? slide?.imageMobile?.src || slide?.image?.src
                  : slide?.image?.src}
                style={{
                  transform: `translateX(${parallaxValues[index]
                    * parallaxDirection}%)`,
                }}
                css={{
                  paddingTop: ASPECT_RATIOS.desktop[size],
                  '@mobile': { paddingTop: ASPECT_RATIOS.mobile[size] },
                }}
              >
                <SlideItem
                  as="img"
                  parallax={parallax && slides.length > 1}
                  src={isMobile
                    ? slide?.imageMobile?.src || slide?.image?.src
                    : slide?.image?.src}
                />
                {(slide.video || slide.videoMobile) ? (
                  <SlideItem
                    as="video"
                    autoPlay
                    muted
                    loop
                    playsInline
                    parallax={parallax && slides.length > 1}
                    src={isMobile
                      ? slide?.videoMobile?.src || slide?.video?.src
                      : slide?.video?.src}
                  />
                ) : null}
              </SlideItemWrapper>
              {(slide?.contentTitle
              || slide?.contentSubtitle
              || slide?.contentButtonLabel)
                ? (
                  <>
                    <Fade />
                    <Content rtl={language.direction === 'rtl'}>
                      {slide?.contentTitle && (
                        <Title>{slide.contentTitle}</Title>
                      )}
                      {slide?.contentSubtitle && (
                        <Label css={{ opacity: 0.5 }}>
                          {slide.contentSubtitle}
                        </Label>
                      )}
                      {slide?.contentButtonLabel && (
                        <Button
                          alt
                          // to={slide.contentButtonLink}
                          css={{ marginTop: '$xs' }}
                        >
                          {slide.contentButtonLabel}
                        </Button>
                      )}
                    </Content>
                  </>
                ) : null}
            </Slide>
          ))}
        </Track>
      </Wrapper>
    </Container>
  );
};

export default Slider;
