import isUndefined from 'lodash/isUndefined';

import TypesHelpers from './types.helpers';
import TypesInternationalization from './types.internationalization';
import TypesSystemCountry from './types.system-country';
import TypesLanguageDicrionaryKeys from './types.language-dictionary-keys';
import TypesStoreColorContexts from './types.store-color-contexts';
import TypesShippingCompanies from './types.shipping-companies';
import TypesContentBuilder from './types.content-builder';
import TypesCommon from './types.common';

import fontPairings from '../assets/fontPairings.json';

const Types = {
  ...TypesHelpers,
  ...TypesInternationalization,
  ...TypesSystemCountry,
  ...TypesLanguageDicrionaryKeys,
  ...TypesStoreColorContexts,
  ...TypesShippingCompanies,
  ...TypesContentBuilder,
  ...TypesCommon,
};

Types.STORE_OID_TOKEN_KEY = 'STORE_OID_TOKEN';
Types.STORE_OID_TOKEN_SEARCH_PARAM = 'storeoidtoken';

Types.SHOP_PRODUCTS_ARCHIVE_PATH = 'shop';

const prettify = (string) => (
  `${string}`
  .replace(/([A-Z])|\.([a-z])/g, ' $1')
  .replace(/[_-]/g, '')
  .replace(/^./, str => str.toUpperCase())
  .replace(/Id/g, 'ID')
);

Object.assign(
  Types,
  Types.createValues('FONT_PAIRINGS', fontPairings.map((fontPairing) => ({
    ...fontPairing,
    label: fontPairing.title,
  }))),
  Types.createValues('ROLE', [{
    id: 'ADMIN',
    label: 'Admin',
  }, {
    id: 'SELLER',
    label: 'Seller',
  }]),
  Types.createValues('STORE_TYPE', [{
    id: 'MERCHANT',
    label: 'Merchant',
  }, {
    id: 'REFERRAL',
    label: 'Referral',
  }, {
    id: 'RESELLER',
    label: 'Reseller',
  }].map(storeType => ({
    ...storeType,
    productType: storeType.productType || `${storeType.id}_PRODUCT`,
  }))),
  Types.createValues('CHANGE_LOG_ACTION', [{
    id: 'CREATE',
    label: 'Create',
  }, {
    id: 'PATCH',
    label: 'Update',
  }, {
    id: 'DELETE',
    label: 'Delete',
  }, {
    id: 'PRODUCTS_STOCK_UPDATE',
    label: 'Stock Update',
  }, {
    id: 'ORDERS_DENY',
    label: 'Denied',
  }, {
    id: 'ORDERS_APPROVE',
    label: 'Approved',
  }, {
    id: 'ORDERS_REFUND',
    label: 'Refunded',
  }, {
    id: 'ORDERS_DELIVERY',
    label: 'On Delivery',
  }, {
    id: 'ORDERS_DELIVERED',
    label: 'Delivered',
  }, {
    id: 'ORDERS_FAILED',
    label: 'Delivery Failed',
  }]),
  Types.createValues('CHANGE_LOG_TARGET_TYPE', [{
    id: 'Store',
    links: {
      ADMIN: '/stores',
      SELLER: '/stores',
    },
  }, {
    id: 'Language',
    links: {
      ADMIN: '/languages',
    },
  }, {
    id: 'DiscountCode',
    links: {
      ADMIN: '/discountcodes',
      SELLER: '/store/discountcodes',
    },
  }, {
    id: 'Product',
    links: {
      ADMIN: '/products',
      SELLER: '/store/products',
    },
  }, {
    id: 'Category',
    links: {
      ADMIN: '/categories',
      SELLER: '/store/categories',
    },
  }, {
    id: 'Collection',
    links: {
      ADMIN: '/collections',
      SELLER: '/store/collections',
    },
  }, {
    id: 'Page',
    links: {
      ADMIN: '/website/pages',
      SELLER: '/website/store/pages',
    },
  }, {
    id: 'ContentBuilderSegment',
    links: {
      ADMIN: ({ target }) => {
        let linkBase = null;
        switch (target?.subject?.type) {
          case 'COMPONENT':
            linkBase = '/website/components';
            break;
          case 'BLOCK':
            linkBase = '/website/store/blocks';
            break;
          case 'LIBRARY_ITEM':
            linkBase = '/website/library';
            break;
          default:
            linkBase = null;
            break;
        }
        if (!linkBase) {
          return null;
        }
        return `${linkBase}/${
          target.subject?._id || target.subject
        }`;
      },
    },
  }, {
    id: 'Document',
    links: {
    ADMIN: '/documents',
      SELLER: '/documents',
    },
  }, {
    id: 'Settings',
    links: {
      ADMIN: (record) => {
        let suffix = null;
        switch (record.target?.name) {
          case 'productsUploadBatch':
            suffix = 'product-upload-batch';
            break;
          case 'productGallerySort':
            suffix = 'product-gallery-sort';
            break;
          case 'productGalleryGenerate':
            suffix = 'product-gallery-generate';
            break;
          case 'contentBuilderLibraryGenerate':
            suffix = 'content-builder-library-generate';
            break;
          case 'magicText':
            suffix = 'magic-text';
            break;
          default:
            suffix = record.target?.name;
            break;
        }
        return `/settings/${suffix}`;
      },
    },
    getLabel: (label) => prettify(label),
  }, {
    id: 'Pricing',
    links: {
      ADMIN: '/shipping/pricing',
    },
  }, {
    id: 'ShippingCompany',
    links: {
      ADMIN: '/shipping/companies',
    },
  }, {
    id: 'Order',
    links: {
      ADMIN: '/orders',
      SELLERS: '/store/orders',
    },
    getLabel: (row, record) => (
      record.target?.subject?._id
      ? `${record.target?.subject?._id}`
      : '-'
    ),
  }].map((item) => {
    item.label = item.id;
    return item;
  })),
  Types.createValues('STORE_SUBSCRIPTION_DURATION', [{
    id: '1_MONTH',
    label: '1 Month',
    labelShort: '1m',
    labelPeriod: 'a month',
    labelPeriodCharge: 'Monthly',
    labelExpires: 'A month from now',
    value: 1,
    unit: 'month',
    name: '1-month',
  }, {
    id: '3_MONTHS',
    label: '3 Months',
    labelShort: '3m',
    labelPeriod: 'every 3 months',
    labelPeriodCharge: 'Quarterly',
    labelExpires: '3 months from now',
    value: 3,
    unit: 'month',
    name: '3-month',
  }, {
    id: '6_MONTHS',
    label: '6 Months',
    labelShort: '6m',
    labelPeriod: 'every 6 months',
    labelPeriodCharge: 'Semi-Annually',
    labelExpires: '6 months from now',
    value: 6,
    unit: 'month',
    name: '6-month',
  }, {
    id: '1_YEAR',
    label: '1 Year',
    labelShort: '1y',
    labelPeriod: 'a year',
    labelPeriodCharge: 'Yearly',
    labelExpires: 'A year from now',
    value: 1,
    unit: 'year',
    name: '1-year',
  }]),
  Types.createValues('STORE_SUBSCRIPTION_MODE', [{
    id: 'OFFLINE',
    label: 'Offline',
  }, {
    id: 'ONLINE',
    label: 'Online',
  }]),
  Types.createValues('STORE_CATEGORIES', [
    'Best Sellers',
    'Electronics',
    'Fashion',
    'Perfumes',
    'Beauty & Skin Care',
    'Baby Products',
    'Educational Products',
    'Toys',
    'Home Appliances',
    'Other',
  ].map(label => ({
    id: label.toUpperCase().replace(/\s+/g, '_'),
    label,
  }))),
  Types.createValues('PRODUCT_KIND', [{
    id: 'TANGIBLE',
    label: 'Tangible',
  }, {
  //   id: 'DIGITAL',
  //   label: 'Digital',
  //   abstract: true,
  // }, {
    id: 'SERVICE',
    label: 'Service',
    abstract: true,
  }]),
  Types.createValues('PRODUCT_OPTION_TYPE', [{
    id: 'CHOICE',
    label: 'Choices',
  }, {
    id: 'TEXT',
    label: 'Text',
  }, {
    id: 'COLOR',
    label: 'Color',
  }, {
    id: 'IMAGE',
    label: 'Image',
  }]),
  Types.createValues('CSV_FORMAT', [{
    id: 'GENERAL',
    label: 'General',
    delimiters: {
      quote: '"',
      column: ',',
      row: '\n',
      decimal: '.',
    },
  }, {
    id: 'EUROPEAN',
    label: 'European',
    delimiters: {
      quote: '"',
      column: ';',
      row: '\n',
      decimal: ',',
    },
  }, {
    id: 'INTERNATIONAL',
    label: 'International',
    delimiters: {
      quote: '"',
      column: ',',
      row: '\n',
      decimal: '.',
    },
  }]),
);

Types.PRODUCT_KIND_ABSTRACT = Types.PRODUCT_KIND.filter(kind => (
  Types.PRODUCT_KIND_MAP[kind].abstract === true
));

Types.RESELLER_COMMISSION_PERCENTAGE_MIN = 0;
Types.RESELLER_COMMISSION_PERCENTAGE_MAX = 80;
Types.RESELLER_COMMISSION_PERCENTAGE_DEFAULT = 20;

Types.RESELLER_ADDED_VALUE_PERCENTAGE_MIN = 0;
Types.RESELLER_ADDED_VALUE_PERCENTAGE_MAX = 50;
Types.RESELLER_ADDED_VALUE_PERCENTAGE_DEFAULT = 0;

Types.STORE_PAGE_PATH_TAC = 'terms-and-conditions';
Types.STORE_PAGE_PATH_SARP = 'shipping-and-return-policy';

Types.FORBIDDEN_SKUS = [
  Types.STORE_PAGE_PATH_TAC,
  Types.STORE_PAGE_PATH_SARP,
];

Types.PACKMAN_STORE_HOSTNAMES = (
     process.env.PACKMAN_STORE_HOSTNAMES
  || process.env.VITE_APP_PACKMAN_STORE_HOSTNAMES
  || ''
  // ).split(/\s*,\s*/);
).split(',');

Types.FORBIDDEN_SLUGS_STORE = [
  'orders',
  'order',
  'admin',
  'tracking',
  'shop',
  'shops',
  'product',
  'products',
  'password-reset',
  'password',
  'activate',
  'signup',
  'reseller',
  'checkout',
  'apple',
  'apple-pay',
  'google',
  'google-pay',
  'wallet',
  'cart',
  'recover',
];

Types.COUNTRIES_DIAL_CODES = Types.COUNTRIES.reduce(
  (agr, country) => {
    country.dialCodes.forEach((dialCode, i) => {
      agr.push({
        value: dialCode,
        label: `+${dialCode} (${country.name})${i > 0 ? ` ${i}` : ''}`,
      });
    });
    return agr;
  },
  [],
);

Object.assign(Types, TypesHelpers.createValues('STORE_PRODUCT_TYPE', (
  Types.STORE_TYPE_ITEMS.map(storeType => ({
    id: storeType.productType,
    storeType: storeType.id,
    label: `${storeType.label} Product`,
  }))
)));

Types.STORE_TYPE_TO_STORE_PRODUCT_TYPE_MAP = Types.STORE_TYPE_ITEMS.reduce(
  (agr, storeType) => {
    agr[storeType.id] = storeType.productType;
    return agr;
  },
  {},
);

Object.assign(
  Types,
  Types.createValues('ANALYTICS_EVENTS', [{
    id: 'PAGE_VIEW',
    label: 'Page View',
  }, {
    id: 'CART_OPEN',
    label: 'Open Cart',
  }, {
    id: 'CART_ADD',
    label: 'Add To Cart',
  }, {
    id: 'CART_REMOVE',
    label: 'Remove From Cart',
  }, {
    id: 'ORDER_SUBMIT',
    label: 'Submit Order',
  }, {
    id: 'ORDER_SUBMIT_SUCCESS',
    label: 'Submit Order Success',
  }, {
    id: 'PRODUCT_SEARCH',
    label: 'Search Products',
  }, {
    id: 'PRODUCT_OPEN',
    label: 'Open Product',
  }]),

  Types.createValues('CHARGED_TO', [{
    id: 'STORE',
    label: 'Store',
  }, {
    id: 'CUSTOMER',
    label: 'Customer',
  }]),

  Types.createValues('PAYMENT_METHOD', [{
    id: 'CARD',
    label: 'Card Online',
    ...Types.createValues('METHODS', [{
      id: 'APPLE_PAY',
      label: 'Apple Pay',
      providers: ['CHECKOUT'],
      checkBrowserSupport: (storeData) => {
        const {
          getCheckoutComApplePaySupportBasedOnStoreId,
        } = Types.getSystemCountry(storeData.systemCountry);
        return (
          window.location.protocol === 'https:'
          && getCheckoutComApplePaySupportBasedOnStoreId(storeData._id)
          && window.ApplePaySession
          && window.ApplePaySession.canMakePayments()
        );
      },
    }]),
  }, {
    id: 'TAMARA',
    label: 'Tamara',
    // deprecated: true,
    sellerDeprecated: true,
    title: 'Financing (tamara.co)',
    ...Types.createValues('METHODS', [{
      id: 'PAY_BY_INSTALMENTS',
      label: 'Pay by Installments',
    }, {
      id: 'PAY_BY_LATER',
      label: 'Shop Now, Pay Later',
    }]),
  }, {
    id: 'TABBY',
    label: 'Tabby',
    title: 'Financing (tabby.ai)',
    ...Types.createValues('METHODS', [{
      id: 'PAY_BY_INSTALMENTS',
      label: 'Pay by Installments',
    // }, {
    //   id: 'PAY_BY_LATER',
    //   label: 'Shop Now, Pay Later',
    }]),
  }, {
    id: 'COD_CASH',
    label: 'Cash on Delivery',
  }, {
    id: 'COD_CARD',
    label: 'Card on Delivery',
  }, {
    id: 'FREE',
    label: 'Discounted',
    hidden: true,
  }], {
    getExtras: (result, name, values, config) => {
      result.PAYMENT_METHOD_TITLE_MAP = values.reduce(
        (agr, value) => {
          const id = config.getId(value);
          const title = value.title || config.getLabel(value);
          agr[id] = title;
          return agr;
        },
        [],
      );
    },
  }),

  Types.createValues('PAYMENT_CARD_PROVIDER', [{
    id: 'CHECKOUT',
    label: 'Checkout',
    online: true,
  }, {
    id: 'PAYMOB',
    label: 'PayMob',
    online: true,
  }, {
    id: 'PAYTABS',
    label: 'PayTabs',
    online: true,
  }, {
    id: 'STRIPE',
    label: 'Stripe',
    online: true,
  // }, {
  //   id: 'DELIVERY',
  //   label: 'Cash',
  //   online: false,
  }]),

  Types.createValues('PAYMENT_PROVIDER', [{
    id: 'CHECKOUT',
    label: 'Checkout',
    online: true,
    gatewayFeesBasedOnCardDetails: true,
  }, {
    id: 'PAYMOB',
    label: 'PayMob',
    online: true,
    gatewayFeesBasedOnCardDetails: true,
  }, {
    id: 'PAYTABS',
    label: 'PayTabs',
    online: true,
  }, {
    id: 'STRIPE',
    label: 'Stripe',
    online: true,
  }, {
    id: 'TAMARA',
    label: 'Tamara',
    online: true,
  }, {
    id: 'TABBY',
    label: 'Tabby',
    online: true,
  }]),

  Types.createValues('PAYMENT_CARD_PROVIDER_STORE_SUPPORTED', [{
    id: 'CHECKOUT',
    label: 'Checkout',
    online: true,
    integrationType: 'Integrated',
    gatewayFeesBasedOnCardDetails: true,
  }, {
    id: 'PAYMOB',
    label: 'PayMob',
    online: true,
    integrationType: 'Payment Page',
    gatewayFeesBasedOnCardDetails: true,
  }, {
    id: 'PAYTABS',
    label: 'PayTabs',
    online: true,
    integrationType: 'Payment Page',
    gatewayFeesBasedOnCardDetails: true,
  }, {
    id: 'STRIPE',
    label: 'Stripe',
    choiceLabel: 'Stripe (COMING SOON)',
    online: true,
    integrationType: 'Integrated',
  }]),

  Types.createValues('DRAFT_PURPOSE', [{
    id: 'POS',
    label: 'POS Checkout',
  }, {
    id: 'TEMPLATE',
    label: 'Template',
  }]),
);

Types.SHIPPING_SCHEDULED_HOUR = 0;

Object.assign(Types, TypesHelpers.createValues('SHIPPING_PRICING_METHOD', [{
  id: 'STANDARD',
  label: 'Standard',
}, {
  id: 'STANDARD_EXPRESS',
  label: 'Special',
}, {
  id: 'INTERNATIONAL',
  label: 'International',
  international: true,
}, {
  id: 'INTERNATIONAL_EXPRESS',
  label: 'International Special',
  international: true,
}]));

Object.assign(Types, TypesHelpers.createValues('SHIPPING_PROVIDER', [{
  id: 'STORE_RUSH',
  label: 'Store Zone Delivery',
  param: 'store-rush',
  description: 'Store own zone delivery.',
  methods: [{
    id: 'STORE_RUSH',
    label: 'Store Zone Delivery',
    durationLabel: '1-3 days',
  }],
}, {
  id: 'STORE',
  label: 'Store Delivery',
  param: 'store',
  description: 'Store handles shipping internally.',
  store: true,
  methods: [{
    id: 'STORE_STANDARD',
    pricingMethod: 'STANDARD',
    label: 'Store Standard',
    durationLabel: '2-3 days',
  }, {
    id: 'STORE_STANDARD_SPECIAL',
    pricingMethod: 'STANDARD_EXPRESS',
    label: 'Store Special',
    durationLabel: '2-3 days',
  }, {
    id: 'STORE_INTERNATIONAL',
    pricingMethod: 'INTERNATIONAL',
    label: 'Store International',
    international: true,
  }, {
    id: 'STORE_INTERNATIONAL_EXPRESS',
    pricingMethod: 'INTERNATIONAL_EXPRESS',
    label: 'Store International Special',
    durationLabel: '1-2 days',
    international: true,
  }],
  supportManualReadyForPickup: true,
  supportScheduledDelivery: true,
}, {
  id: 'PACKMAN_SHOP',
  label: 'Packman',
  param: 'packman-shop',
  description: 'Packman team handles the shipping for you.',
  methods: [{
    id: 'PACKMAN_SHOP_STANDARD',
    pricingMethod: 'STANDARD',
    label: 'Standard',
    durationLabel: '2-3 days',
  }, {
    id: 'PACKMAN_SHOP_STANDARD_SPECIAL',
    pricingMethod: 'STANDARD_EXPRESS',
    label: 'Standard Special',
    durationLabel: '2-3 days',
  }, {
    id: 'PACKMAN_SHOP_INTERNATIONAL',
    pricingMethod: 'INTERNATIONAL',
    label: 'International',
  }, {
    id: 'PACKMAN_SHOP_INTERNATIONAL_EXPRESS',
    pricingMethod: 'INTERNATIONAL_EXPRESS',
    label: 'International Special',
    durationLabel: '1-2 days',
  }],
  supportManualReadyForPickup: true,
  supportScheduledDelivery: true,
}, {
  id: 'PACKMAN_RUSH',
  label: 'Packman RUSH',
  param: 'packman-rush',
  description: 'Packman own rush delivery.',
  methods: [{
    id: 'PACKMAN_RUSH',
    label: 'Packman RUSH™',
    durationLabel: '2 hours',
  }],
}, {
  id: 'BOXIT',
  label: 'Boxit',
  param: 'boxit',
  description: '',
  deprecated: true,
  methods: [{
    id: 'BOXIT_STANDARD',
    label: 'Boxit Standard',
  }, {
    id: 'BOXIT_INTERNATIONAL_ECONOMY',
    label: 'Boxit International (Economy)',
  }, {
    id: 'BOXIT_INTERNATIONAL_EXPRESS',
    label: 'Boxit International (Express)',
  }],
}, {
  id: 'SELF_PICKUP',
  label: 'Self Pickup',
  param: 'self-pickup',
  description: 'Customer will pickup the goods in person.',
  methods: [{
    id: 'SELF_PICKUP',
    label: 'Self Pickup',
  }],
}, {
  id: 'POS',
  label: 'POS Checkout',
  param: 'pos',
  methods: [{
    id: 'POS',
    label: 'POS Checkout',
  }],
  description: 'Customer checks out at the register.',
}, {
  id: 'ABSTRACT',
  label: 'No Shipping',
  param: 'abstract',
  description: 'Providing services and selling non tangible goods.',
  abstract: true,
  methods: [{
    id: 'ABSTRACT',
    label: 'No Shipping',
  }],
}].map(shippingProvider => ({
  ...shippingProvider,
  abstract: !!shippingProvider.abstract,
  deprecated: !!shippingProvider.deprecated,
  description: shippingProvider.description || '',
  methods: shippingProvider.methods.map(shippingMethod => ({
    ...shippingMethod,
    store: !!shippingProvider.store,
    provider: shippingProvider.id,
    deprecated: !!shippingMethod.deprecated || !!shippingProvider.deprecated,
    durationLabel: shippingMethod.durationLabel || null,
  })),
}))));

Object.assign(Types, Types.SHIPPING_PROVIDER_ITEMS.reduce(
  (agr, shippingProvider) => {
    agr.SHIPPING_METHOD_ITEMS.push(...shippingProvider.methods);
    if (!shippingProvider.deprecated) {
      agr.SHIPPING_PROVIDER_USED.push(shippingProvider.id);
    }
    if (!shippingProvider.abstract) {
      agr.SHIPPING_PROVIDER_TANGIBLE.push(shippingProvider.id);
    }
    if (shippingProvider.store) {
      agr.SHIPPING_PROVIDER_STORE.push(shippingProvider.id);
    }
    agr.SHIPPING_PROVIDER_DESCRIPTIONS.push(shippingProvider.description);
    agr.SHIPPING_PROVIDER_DESCRIPTIONS_MAP[shippingProvider.id] = (
      shippingProvider.description
    );
    [
      [
        'supportManualReadyForPickup',
        'SHIPPING_PROVIDER_MANUAL_READY_FOR_PICKUP_SUPPORT',
      ], [
        'supportScheduledDelivery',
        'SHIPPING_PROVIDER_SCHEDULED_DELIVERY_SUPPORT',
      ],
    ].forEach(([key, agrKey]) => {
      if (shippingProvider[key]) {
        agr[`${agrKey}`].push(shippingProvider.id);
        agr[`${agrKey}_MAP`][shippingProvider.id] = true;
      } else {
        agr[`${agrKey}_MAP`][shippingProvider.id] = false;
      }
    });
    agr.SHIPPING_PROVIDER_METHOD_MAP[shippingProvider.id] = (
      shippingProvider.methods.map(shippingMethod => shippingMethod.id)
    );
    shippingProvider.methods.forEach((shippingMethod) => {
      agr.SHIPPING_METHOD_PROVIDER_MAP[
        shippingMethod.id
      ] = shippingProvider.id;
      if (shippingMethod.pricingMethod) {
        agr.SHIPPING_METHOD_USING_PRICING.push(shippingMethod.id);
        agr.SHIPPING_METHOD_USING_PRICING_ITEMS.push(shippingMethod);
        agr.SHIPPING_METHOD_PRICING_METHOD_MAP[shippingMethod.id] = (
          shippingMethod.pricingMethod
        );
        if (!agr.SHIPPING_PRICING_METHOD_PROVIDER_METHOD_MAP[
          shippingProvider.id
        ]) {
          agr.SHIPPING_PRICING_METHOD_PROVIDER_METHOD_MAP[
            shippingProvider.id
          ] = {};
          agr.SHIPPING_PRICING_METHOD_PROVIDER_METHOD_ITEMS_MAP[
            shippingProvider.id
          ] = {};
        }
        agr.SHIPPING_PRICING_METHOD_PROVIDER_METHOD_MAP[shippingProvider.id][
          shippingMethod.pricingMethod
        ] = shippingMethod.id;
        agr.SHIPPING_PRICING_METHOD_PROVIDER_METHOD_ITEMS_MAP[
          shippingProvider.id
        ][
          shippingMethod.pricingMethod
        ] = shippingMethod;
      }
    });
    return agr;
  },
  {
    SHIPPING_METHOD_ITEMS: [],
    SHIPPING_PROVIDER_STORE: [],
    SHIPPING_PROVIDER_USED: [],
    SHIPPING_PROVIDER_TANGIBLE: [],
    SHIPPING_PROVIDER_DESCRIPTIONS: [],
    SHIPPING_PROVIDER_DESCRIPTIONS_MAP: {},
    SHIPPING_PROVIDER_MANUAL_READY_FOR_PICKUP_SUPPORT: [],
    SHIPPING_PROVIDER_MANUAL_READY_FOR_PICKUP_SUPPORT_MAP: {},
    SHIPPING_PROVIDER_SCHEDULED_DELIVERY_SUPPORT: [],
    SHIPPING_PROVIDER_SCHEDULED_DELIVERY_SUPPORT_MAP: {},
    SHIPPING_PROVIDER_METHOD_MAP: {},
    SHIPPING_METHOD_PROVIDER_MAP: {},
    SHIPPING_METHOD_USING_PRICING: [],
    SHIPPING_METHOD_USING_PRICING_ITEMS: [],
    SHIPPING_METHOD_PRICING_METHOD_MAP: {},
    SHIPPING_PRICING_METHOD_PROVIDER_METHOD_MAP: {},
    SHIPPING_PRICING_METHOD_PROVIDER_METHOD_ITEMS_MAP: {},
  },
));

Object.assign(Types, TypesHelpers.createValues('SHIPPING_METHOD', (
  Types.SHIPPING_METHOD_ITEMS
)));

Object.assign(Types, Types.SHIPPING_METHOD_ITEMS.reduce(
  (agr, shippingMethod) => {
    if (!shippingMethod.deprecated) {
      agr.SHIPPING_METHOD_USED.push(shippingMethod.id);
    }
    agr.SHIPPING_METHOD_DURATION_LABELS.push(shippingMethod.durationLabel);
    agr.SHIPPING_METHOD_DURATION_LABELS_MAP[shippingMethod.id] = (
      shippingMethod.durationLabel
    );
    return agr;
  },
  {
    SHIPPING_METHOD_USED: [],
    SHIPPING_METHOD_DURATION_LABELS: [],
    SHIPPING_METHOD_DURATION_LABELS_MAP: {},
  },
));

Object.assign(Types, Types.createValues('SHIPPING_STORAGE', [{
  id: 'LOCAL',
  label: 'Local',
}, {
  id: 'WAREHOUSE',
  label: 'Packman Warehouse',
  labelShort: 'Warehouse',
}, {
  id: 'ABSTRACT',
  label: 'Abstract',
  abstract: true,
}].map(shippingStorage => ({
  ...shippingStorage,
  labelShort: shippingStorage.labelShort || shippingStorage.label,
  abstract: !!shippingStorage.abstract,
}))));

Object.assign(Types, Types.SHIPPING_STORAGE_ITEMS.reduce(
  (agr, shippingStorage) => {
    if (!shippingStorage.abstract) {
      agr.SHIPPING_STORAGE_TANGIBLE.push(shippingStorage.id);
    }
    agr.SHIPPING_STORAGE_LABELS_SHORT.push(shippingStorage.labelShort);
    agr.SHIPPING_STORAGE_LABELS_SHORT_MAP[shippingStorage.id] = (
      shippingStorage.labelShort
    );
    return agr;
  },
  {
    SHIPPING_STORAGE_TANGIBLE: [],
    SHIPPING_STORAGE_LABELS_SHORT: [],
    SHIPPING_STORAGE_LABELS_SHORT_MAP: {},
  },
));

Object.assign(Types, TypesHelpers.createValues('ORDER_STATUS', [{
  id: 'INVALID',
  label: 'Invalid',
  unlistable: true,
}, {
  id: 'INVALID_PAYMENT',
  label: 'Payment Failed',
  unlistableSeller: true,
}, {
  id: 'INVALID_APPROVAL',
  label: 'Denied',
}, {
  id: 'INVALID_SHIPPING',
  label: 'Invalid Shipping',
  // unlistable: true,
}, {
  id: 'DRAFT',
  label: 'Draft',
}, {
  id: 'PAYMENT',
  label: 'Pending Payment',
  // unlistable: true,
}, {
  id: 'APPROVAL',
  label: 'Pending Approval',
}, {
  id: 'NEW',
  label: 'New',
}, {
  id: 'DELIVERY',
  label: 'Processing',
}, {
  id: 'DELIVERED',
  label: 'Delivered',
}, {
  id: 'FAILED',
  label: 'Failed',
}].map(orderStatus => ({
  ...orderStatus,
  unlistable: !!orderStatus.unlistable || false,
  unlistableSeller: !!orderStatus.unlistable || !!orderStatus.unlistableSeller,
}))));

Object.assign(Types, Types.ORDER_STATUS_ITEMS.reduce(
  (agr, orderStatus) => {
    if (!orderStatus.unlistable) {
      agr.ORDER_STATUS_LISTABLE.push(orderStatus.id);
      if (!orderStatus.unlistableSeller) {
        agr.ORDER_STATUS_LISTABLE_SELLER.push(orderStatus.id);
      }
    }
    return agr;
  },
  {
    ORDER_STATUS_LISTABLE: [],
    ORDER_STATUS_LISTABLE_SELLER: [],
    ORDER_STATUS_CHART: [
      ['NEW', 'APPROVAL'],
      ['DELIVERY'],
      ['DELIVERED'],
      ['FAILED', 'INVALID_APPROVAL'],
    ],
    ORDER_STATUS_CHART_LABELS: [
      'New',
      'Processing',
      'Delivered',
      'Failed',
    ],
  },
));

Object.assign(
  Types,
  TypesHelpers.createValues('STORE_EXPIREABLE', [{
    id: 'PLAN',
    label: 'Plan',
  }, {
    id: 'DOMAIN',
    label: 'Domain',
  }, {
    id: 'WAREHOUSE',
    label: 'Warehouse',
  }]),
  TypesHelpers.createValues('STORE_EXPIREABLE_PERIOD_UNIT', [{
    id: 'MONTH',
    label: 'Month(s)',
    momentUnit: 'month',
  }, {
    id: 'WEEK',
    label: 'Week(s)',
    momentUnit: 'week',
  }, {
    id: 'DAY',
    label: 'Day(s)',
    momentUnit: 'day',
  }]),
);

Object.assign(Types, TypesHelpers.createValues('STORE_SHOP_DEFAULT_SORT', [{
  id: 'date',
  label: 'Latest',
}, {
  id: 'category',
  label: 'Category',
}, {
  id: 'price-asc',
  label: 'Price Asc',
}, {
  id: 'price-desc',
  label: 'Price Desc',
}]));

Types.ORDER_STATUS_CHART_AVAILABLE = Types.ORDER_STATUS_CHART.reduce(
  (agr, statuses) => {
    agr.push(...statuses);
    return agr;
  },
  [],
);

Types.DEFAULT_WORKING_HOURS = new Array(7).join('.').split('.').map(
  (_, i) => (i > 4 ? [-1] : [8, 20])
);

Types.PAYTABS_PAYMENT_STATUS = {
  PAY_PAGE_CREATED: '4012',
  SUCCESS: '100',
  AUTHORIZATION: '111',
  CAPTURE: '113',
  VOID: '116',
  REFUND_ACCEPTED: '812',
  REFUND_COMPLETE: '814',
  AUTHORIZATION_INVALID: '4004',
  AUTHORIZATION_EXPIRED: '4010',
};

Types.VAT = 0.05;

Types.PACKMAN_TRN = null;

Types.IMAGE_SIZING = [
  'COVER',
  'CONTAIN',
];

Types.IMAGE_SIZING_LABELS = [
  'Cover',
  'Contain',
];

Types.IMAGE_SIZING_LABELS_MAP = Types.IMAGE_SIZING.reduce(
  (agr, imageSize, index) => {
    agr[imageSize] = Types.IMAGE_SIZING_LABELS[index];
    return agr;
  },
  {},
);

Types.IMAGE_SIZING_PRODUCT = [
  'DEFAULT',
  'COVER',
  'CONTAIN',
];

Types.IMAGE_SIZING_PRODUCT_LABELS = [
  'Default',
  'Cover',
  'Contain',
];

Types.IMAGE_SIZING_PRODUCT_LABELS_MAP = Types.IMAGE_SIZING_PRODUCT.reduce(
  (agr, imageSize, index) => {
    agr[imageSize] = Types.IMAGE_SIZING_PRODUCT_LABELS[index];
    return agr;
  },
  {},
);

Object.assign(Types, Types.createValues('PAGE_CONTENT_FIELDS', (
  [
    'header',
    'aboutUs',
    'callToAction',
    'featuredProducts',
    'socialMedia',
  ].reduce(
    (agr, prefix, i) => {
      const suffixes = [
        ['Title', 'TEXT'],
        ['Subtitle', 'TEXT'],
        ['Body', 'TEXT'],
      ];
      if (i < 3) {
        suffixes.push(['Image', 'IMAGE']);
      }
      suffixes.forEach(([suffix, type]) => {
        const id = `${prefix}${suffix}`;
        agr.push({
          id,
          label: prettify(suffix),
          type,
          section: prefix,
        });
      });
      return agr;
    },
    [],
  )
)));

Object.assign(Types, Types.createValues('PAGE_CONTENT_FIELDS_SECTIONS', (
  [
    'header',
    'aboutUs',
    'callToAction',
    'featuredProducts',
    'socialMedia',
  ].map((id) => ({
    id,
    label: prettify(id),
    fieldIds: Types.PAGE_CONTENT_FIELDS_ITEMS.reduce(
      (agr, field) => {
        if (field.section === id) {
          agr.push(field.id);
        }
        return agr;
      },
      [],
    ),
  }))
)));

Object.assign(Types, Types.createValues('PRODUCTS_IMPORT_STATUS', [{
  id: 'NEW',
  label: 'New',
}, {
  id: 'READY',
  label: 'Queued',
}, {
  id: 'IMPORTING',
  label: 'Importing',
}, {
  id: 'DONE',
  label: 'Done',
}, {
  id: 'STOP',
  label: 'Stopping',
}]));

Object.assign(Types, Types.createValues('ONBOARDING_STEPS', [{
  id: 'STORE',
  label: 'Store',
  // eslint-disable-next-line max-len
  description: 'Configure your store basics here. You can update the name and link for your store and choose industries that best describe your store. If you need help at any point, you can contact our support team from the chat widget below.',
}, {
  id: 'LANGUAGES',
  label: 'Languages',
  // eslint-disable-next-line max-len
  description: 'Choose the languages you want to support on your store. You can add more languages later.',
}, {
  id: 'BRAND',
  label: 'Brand',
  // eslint-disable-next-line max-len
  description: 'This is your brand page, where you can customize your brand colors, logo, and favicon. These elements create a visual identity for your brand and help you stand out from the competition.',
}, {
  id: 'STYLE',
  label: 'Style',
  // eslint-disable-next-line max-len
  description: 'Configure your website style here, including light/dark mode, roundness, and transition style. These customizations can enhance user experience and they reflect your brand\'s style.',
}, {
  id: 'FONTS',
  label: 'Fonts',
  // eslint-disable-next-line max-len
  description: 'Customize your website\'s font family, weight, text transform, and letter spacing for the title, body, and label fonts. Choose from a wide selection of fonts in the Google Fonts library.',
}, {
  id: 'ADDRESS',
  label: 'Address',
  // eslint-disable-next-line max-len
  description: 'Enter the address of your business.',
}, {
  id: 'STORAGE',
  label: 'Storage',
  // eslint-disable-next-line max-len
  description: 'Decide how you want to manage your inventory: locally or with our platform\'s warehouse storage.',
}, {
  id: 'CATEGORIES',
  label: 'Categories',
// eslint-disable-next-line max-len
}, {
  id: 'PRODUCTS',
  label: 'Products',
  // eslint-disable-next-line max-len
    description: '',
}, {
  id: 'CONTENT',
  label: 'Content',
  description: '',
}, {
  id: 'HOMEPAGE',
  label: 'Homepage',
  // eslint-disable-next-line max-len
    description: '',
}, {
  id: 'END',
  label: 'End',
  description: 'You\'ve finished the setup! 🎉',
}]));

Object.assign(Types, Types.createValues('ONBOARDING_DOMAIN_OPTIONS', [{
  id: 'ALREADY_PURCHASED',
  label: 'Already own one',
}, {
  id: 'WANT_TO_PURCHASE',
  label: 'Purchase with Packman®',
}, {
  id: 'NOT_INTERESTED',
  label: 'Not Interested',
}]));

// eslint-disable-next-line max-len
Types.NETLIFY_WEBHOOK_URL_BUILD_HOMEPAGE = 'https://api.netlify.com/build_hooks/5eea10c8d8ca06e053ce9983';

Types.getSimpleAnalyticsHostname = (storeId, env) => {
  env = (
      isUndefined(env)
    ? (process.env.VITE_APP_DXTOOLS_ENV || process.env.DXTOOLS_ENV)
    : (env === null || env === '')
    ? 'production'
    : undefined
  );
  return `${storeId}${
    env && env.length && env !== 'production'
    ? `.${env}`
    : ''
  }.store.shop.packman.app`;
};

Types.CHECKOUT_COM_LOCALIZATIONS = [{
  label: 'Arabic',
  value: 'AR',
}, {
  label: 'Chinese Simplified',
  value: 'ZH-CH',
}, {
  label: 'Chinese Traditional (Hong Kong)',
  value: 'ZH-HK',
}, {
  label: 'Chinese Traditional (Taiwan)',
  value: 'ZH-TW',
}, {
  label: 'Danish',
  value: 'DA-DK',
}, {
  label: 'Dutch',
  value: 'NL-NL',
}, {
  label: 'English',
  value: 'EN-GB',
}, {
  label: 'Filipino',
  value: 'FIL-PH',
}, {
  label: 'Finnish',
  value: 'FI-FI',
}, {
  label: 'French',
  value: 'FR-FR',
}, {
  label: 'German',
  value: 'DE-DE',
}, {
  label: 'Hindi',
  value: 'HI-IN',
}, {
  label: 'Indonesian',
  value: 'ID-ID',
}, {
  label: 'Italian',
  value: 'IT-IT',
}, {
  label: 'Japanese',
  value: 'JA-JP',
}, {
  label: 'Korean',
  value: 'KO-KR',
}, {
  label: 'Malay',
  value: 'MS-MY',
}, {
  label: 'Norwegian Bokmål',
  value: 'NB-NO',
}, {
  label: 'Spanish',
  value: 'ES-ES',
}, {
  label: 'Swedish',
  value: 'SV-SE',
}, {
  label: 'Thai',
  value: 'TH-TH',
}, {
  label: 'Vietnamese',
  value: 'VI-VN',
}];

Types.CONSTANTS = {};

Types.CONSTANTS.StoreType = Types.createConstant(
  Types.STORE_TYPE,
);
Types.CONSTANTS.StoreProductType = Types.createConstant(
  Types.STORE_PRODUCT_TYPE,
);
Types.CONSTANTS.StoreSubscriptionDuration = Types.createConstant(
  Types.STORE_SUBSCRIPTION_DURATION,
);
Types.CONSTANTS.StoreSubscriptionMode = Types.createConstant(
  Types.STORE_SUBSCRIPTION_MODE,
);
Types.CONSTANTS.ProductKind = Types.createConstant(
  Types.PRODUCT_KIND,
);
Types.CONSTANTS.OrderStatus = Types.createConstant(
  Types.ORDER_STATUS,
);
Types.CONSTANTS.ShippingPricingMethod = Types.createConstant(
  Types.SHIPPING_PRICING_METHOD,
);
Types.CONSTANTS.ShippingMethod = Types.createConstant(
  Types.SHIPPING_METHOD,
);
Types.CONSTANTS.ShippingProvider = Types.createConstant(
  Types.SHIPPING_PROVIDER,
);
Types.CONSTANTS.ShippingStorage = Types.createConstant(
  Types.SHIPPING_STORAGE,
);
Types.CONSTANTS.PaymentMethod = Types.createConstant(
  Types.PAYMENT_METHOD,
);
Types.CONSTANTS.PaymentCardProvider = Types.createConstant(
  Types.PAYMENT_CARD_PROVIDER,
);
Types.CONSTANTS.PaymentProvider = Types.createConstant(
  Types.PAYMENT_PROVIDER,
);
Types.CONSTANTS.ImageSizing = Types.createConstant(
  Types.IMAGE_SIZING,
);
Types.CONSTANTS.ImageSizingProduct = Types.createConstant(
  Types.IMAGE_SIZING_PRODUCT,
);

export default Types;
