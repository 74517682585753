import debounce from 'lodash/debounce';
import isArray from 'lodash/isArray';

import {
  stringify as stringifyQuery,
} from 'querystring';

import React, {
  useState,
  useMemo,
  useRef,
  useEffect,
} from 'react';
import Helmet from 'react-helmet';

import { useHistory } from 'react-router-dom';
import ObjectId from 'bson-objectid';

import { createNamedStyled, keyframes } from '../../../stitches.config';

import { useLocation } from '../../../context/Location';
import { useStore } from '../../../context/Store';
import { useAnalytics } from '../../../context/Analytics';
import { parseProduct } from '../../../context/Product';

import { useDictionary } from '../../../context/Language';

import Types from '../../../modules/types';

import { Title, Paragraph } from '../../../components/Elements/Text';
import GridComponent from '../../../components/Patterns/Grid';
import Card from '../../../components/Elements/Card';
import Slider from '../../../components/Legacy/Slider';

import Sidebar from './Sidebar';

import { ApiBoundInfiniteScrollQuery } from
  '../../../helpers/InfiniteScrollQuery';

const NO_CATEGORY_OBJECT_ID = `${ObjectId()}`;
const NO_COLLECTION_OBJECT_ID = `${ObjectId()}`;

const styled = createNamedStyled('Products');

const {
  CONSTANTS: {
    StoreType,
  },
} = Types;

const normalizeInputValue = (value) => {
  if (!value || !value.length) {
    return null;
  }
  value = value.trim().toLowerCase();
  if (!value.length) {
    return null;
  }
  return value;
};

export const useDebouncedInputValueState = (
  defaultValue = '',
  timeout = 300,
  normalize = normalizeInputValue,
) => {
  const ref = useRef({
    value: defaultValue,
    normalized: normalize(defaultValue),
  });
  const [state, setState] = useState({ ...ref.current });
  const debounced = useMemo(
    () => debounce(
      (value) => {
        ref.current.normalized = normalize(value);
        setState({ ...ref.current });
      },
      timeout,
    ),
    [normalize, timeout],
  );
  const setStateDebounced = useMemo(
    () => (value) => {
      ref.current.value = value;
      setState({ ...ref.current });
      debounced(value);
    },
    [debounced],
  );
  const setStateImmediate = useMemo(
    () => (value) => {
      debounced.cancel();
      ref.current.value = value;
      ref.current.normalized = normalize(value);
      setState({ ...ref.current });
    },
    [normalize, debounced],
  )
  return [state, setStateDebounced, setStateImmediate];
};

const Wrapper = styled.named('Wrapper')('div', {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  maxWidth: '$siteWidth',
  margin: '0 auto',
  // gap: '$l',
});

const Grid = styled.named('Grid')(GridComponent, {
  // marginTop: '$s',
  gridGap: '$xs $s',

  // '@desktop+': {
  //   // marginTop: 0,
  //   // marginLeft: '$l',
  //   // marginRight: '-$s',
  //   justifyContent: 'flex-end',
  //   gridGap: '$s',
  // },
});

const Items = styled.named('Items')('div', {
  display: 'flex',
  position: 'relative',
  flexDirection: 'column',
  width: '100%',

  padding: '$s',
  '@desktop+': {
    flexDirection: 'row',
    padding: '$m',
    gap: '$l',
  },
});

const fadeIn = keyframes({
  '0%': { opacity: 0 },
  '100%': { opacity: 1 },
});

const EmptyWrapper = styled.named('EmptyWrapper')('div', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  padding: '$m',
  gap: '$s',

  width: '100vw',
  '@desktop+': { width: '300%' },

  // TODO: Revisit this to make it more dynamic
  opacity: 0,
  animation: `${fadeIn} $transitions$m $transitions$xl forwards`,
});

const renderEmpty = ({ title, text }) => (
  <EmptyWrapper>
    <Title>{title}</Title>
    <Paragraph opacity={0.5} fontSize={13}>{text}</Paragraph>
  </EmptyWrapper>
);

export default function StoreProducts() {
  const { data: store } = useStore();
  const analytics = useAnalytics();

  const {
    filtersAllCategories,
    filtersAllCollections,
    filtersSortByLatest,
    filtersSortByCategory,
    filtersSortByPriceAsc,
    filtersSortByPriceDesc,
    filtersSearchEmptyTitle,
    filtersSearchEmptyText,
    currency: dictionaryCurrency,
  } = useDictionary();

  const { currency: systemCurrency } = Types.getSystemCountry(
    store.systemCountry,
  );

  const currency = dictionaryCurrency[systemCurrency];

  const {
    categories = [],
    collections = [],
    productsCount = 0,
    shopDefaultSort = 'date',
  } = store;
  const { searchParams } = useLocation();
  const history = useHistory();
  const {
    categoryOption,
    subcategoryOption,
    categoryOptions,
    categoryOptionsSlugIdMap,
    categoryOptionsSlugNameMap,
    categoryOptionsSlugSubcategoryIdMap,
    categoryOptionsSlugSubcategoryNameMap,
  } = useMemo(
    () => {
      const slugIdMap = {};
      const slugNameMap = {};
      const slugSubcategoryIdMap = {};
      const slugSubcategoryNameMap = {};
      const result = [{
        label: filtersAllCategories,
        value: null,
        products: productsCount,
        subcategories: [],
      }];
      let option = null;
      let suboption = null;
      categories.forEach(({
        _id,
        slug,
        name,
        products,
        subcategories = [],
      }) => {
        slugIdMap[slug] = _id;
        slugNameMap[slug] = name;
        const categoryOptionTemp = {
          label: name,
          value: slug,
          products,
          subcategories: [],
        };
        if (searchParams.category === slug) {
          option = categoryOptionTemp;
        }
        slugSubcategoryIdMap[slug] = {};
        slugSubcategoryNameMap[slug] = {};
        for (let i = 0; i < subcategories.length; i++) {
          const subcategory = subcategories[i];
          slugSubcategoryIdMap[slug][subcategory.slug] = subcategory._id;
          slugSubcategoryNameMap[slug][subcategory.slug] = subcategory.name;
          const subcategoryOptionTemp = {
            label: subcategory.name,
            value: subcategory.slug,
            category: categoryOptionTemp,
          };
          categoryOptionTemp.subcategories.push(subcategoryOptionTemp);
          if (option === categoryOptionTemp) {
            if (searchParams.sub === subcategory.slug) {
              suboption = subcategoryOptionTemp;
            }
          }
        }
        result.push(categoryOptionTemp);
        // TODO We already have the product count from store
        // result[0].products += products;
      });
      return {
        categoryOption: option,
        subcategoryOption: suboption,
        categoryOptions: result,
        categoryOptionsSlugIdMap: slugIdMap,
        categoryOptionsSlugNameMap: slugNameMap,
        categoryOptionsSlugSubcategoryIdMap: slugSubcategoryIdMap,
        categoryOptionsSlugSubcategoryNameMap: slugSubcategoryNameMap,
      };
    },
    [
      searchParams.category,
      searchParams.sub,
      categories,
      filtersAllCategories,
      productsCount,
    ],
  );
  const {
    collectionOption,
    collectionOptions,
    collectionOptionsSlugIdMap,
    collectionOptionsSlugNameMap,
  } = useMemo(
    () => {
      const slugIdMap = {};
      const slugNameMap = {};
      const result = [{
        label: filtersAllCollections,
        value: null,
        products: productsCount,
      }];
      let option = null;
      collections.forEach(({
        _id,
        slug,
        name,
        products,
      }) => {
        slugIdMap[slug] = _id;
        slugNameMap[slug] = name;
        const collectionOptionTemp = {
          label: name,
          value: slug,
          products,
        };
        if (searchParams.collection === slug) {
          option = collectionOptionTemp;
        }
        result.push(collectionOptionTemp);
      });
      return {
        collectionOption: option,
        collectionOptions: result,
        collectionOptionsSlugIdMap: slugIdMap,
        collectionOptionsSlugNameMap: slugNameMap,
      };
    },
    [
      searchParams.collection,
      collections,
      filtersAllCollections,
      productsCount,
    ],
  );
  const sortOptions = useMemo(
    () => [
      ['date', filtersSortByLatest, {
        featured: -1,
        publishedAt: -1,
        _id: 1,
      }],
      ['category', filtersSortByCategory, {
        categorySortWeight: -1,
        featured: -1,
        _id: 1,
      }],
      ['price-asc', filtersSortByPriceAsc, {
        featured: -1,
        'variations.0.price': 1,
        _id: 1,
      }],
      ['price-desc', filtersSortByPriceDesc, {
        featured: -1,
        'variations.0.price': -1,
        _id: 1,
      }],
    ].map(([key, label, values]) => ({
      key,
      label,
      value: key,
      sort: values,
    })),
    [
      filtersSortByLatest,
      filtersSortByCategory,
      filtersSortByPriceAsc,
      filtersSortByPriceDesc,
    ],
  );
  const sortValue = useMemo(
    () => (
      (
        sortOptions.find(({ key }) => key === (
          searchParams.sort || shopDefaultSort
        ))
        || sortOptions.find(({ key }) => key === shopDefaultSort)
      ).sort
    ),
    [sortOptions, searchParams.sort, shopDefaultSort],
  );
  const [searchDebounced, setSearchDebounced] = useDebouncedInputValueState('');
  const renderItem = useMemo(
    () => (item, index, { search }) => (
      <Card
        key={item._id}
        product={item}
        search={search}
      />
    ),
    [],
  );
  const getProductsInfiniteScrollQeuryParams = useMemo(
    () => (
      params,
      {
        storeType,
        storeId,
        categoryId,
        subcategoryId,
        collectionId,
        sort,
        search,
      },
    ) => Object.assign(
      {
        ...params,
        excludeFuturePublishedAt: true,
        where: {
          AND: [
            {
              ...(params.where || {}),
              store: storeId,
            },
            search
              ? {
                OR: [
                  'name',
                  'i18n.name',
                  'variations.name',
                  'variations.i18n.name',
                  'variations.storeSku',
                ].map(key => ({ [key]: { REGEX: search, OPTIONS: 'i' } })),
              }
              : null,
            (
                categoryId === null
              ? { category: NO_CATEGORY_OBJECT_ID }
              : typeof categoryId !== 'undefined'
              ? (
                  typeof subcategoryId !== 'undefined'
                ? {
                    category: categoryId || undefined,
                    subcategory: subcategoryId || undefined,
                  }
                : {
                    OR: [
                      { category: categoryId || undefined },
                      { categories: categoryId || undefined },
                    ],
                  }
                )
              : undefined
            ),
            (
                collectionId === null
              ? { collections: NO_COLLECTION_OBJECT_ID }
              : typeof collectionId !== 'undefined'
              ? { collections: collectionId }
              : undefined
            ),
          ].filter(condition => condition),
        },
        sort,
      },
      storeType === StoreType.REFERRAL
        ? {
          populate: {
            referralStore: true,
            referralProduct: true,
          },
        }
        : {}
    ),
    [],
  );
  const shouldProductsInfiniteQueryReload = useMemo(
    () => (oldProps, newProps) => (
      oldProps.categoryId !== newProps.categoryId
      || oldProps.subcategoryId !== newProps.subcategoryId
      || oldProps.collectionId !== newProps.collectionId
      || oldProps.storeId !== newProps.storeId
      || oldProps.sort !== newProps.sort
      || oldProps.search !== newProps.search
    ),
    [],
  );
  const extractDataFromInfiniteScrollQuery = useMemo(
    () => (
      response => (
        isArray(response)
          ? response
          : []
      )
        .map(product => parseProduct(product, store, currency, false))
    ),
    [store, currency],
  );

  // const [top, setTop] = useState(0);
  const ref = useRef();

  // useEffect(() => {
  //   const timeout = setTimeout(() => {
  //     setTop(ref.current.getBoundingClientRect().top);
  //   }, 300);
  //   return () => clearTimeout(timeout);
  // }, []);

  // useEffect(
  //   () => {
  //     const timeout = setTimeout(() => {
  //       // window.scrollTo({
  //       //   top: top - 20,
  //       //   behavior: 'smooth',
  //       // });
  //     }, 500);
  //     return () => clearTimeout(timeout);
  //   },
  //   [searchParams.category, top],
  // );

  useEffect(
    () => {
      analytics.trigger.PRODUCT_SEARCH({
        searchString: searchDebounced.value,
        category: (
            categoryOptionsSlugNameMap[searchParams.category]
          ? `${
              categoryOptionsSlugNameMap[searchParams.category]
            }${
              subcategoryOption
              ? ` / ${
                  categoryOptionsSlugSubcategoryNameMap[searchParams.category]
                  ?.[searchParams.sub]
                }`
              : ''
            }`
          : undefined
        ),
        collection: (
          collectionOptionsSlugNameMap[searchParams.collection]
          ? `${collectionOptionsSlugNameMap[searchParams.collection]}`
          : undefined
        ),
      });
    },
    [
      searchDebounced.value,
      searchParams.category,
      searchParams.sub,
      searchParams.collection,
      subcategoryOption,
      categoryOptionsSlugNameMap,
      categoryOptionsSlugSubcategoryNameMap,
      collectionOptionsSlugNameMap,
      analytics,
    ],
  );

  return (
    <Wrapper>
      {
        categoryOption
          ? (
            <Helmet>
              <title>
                {`${store.name} | ${categoryOption.label}${
                  subcategoryOption
                  ? ` / ${subcategoryOption.label}`
                  : ''
                }`}
              </title>
            </Helmet>
          )
          : collectionOption
          ? (
              <Helmet>
                <title>
                  {`${store.name} | ${collectionOption.label}`}
                </title>
              </Helmet>
            )
          : null
      }
      {
        store.shopLayout?.banners?.hidden !== true && store.shopBanners.length
          ? (
            <Slider
              size="small"
              autoplay={store.shopBannersAutoplay}
              overflow={store.shopBannersOverflow}
              slides={store.shopBanners}
            />
          )
          : null
      }
      <Items ref={ref}>
        {
          store.shopLayout?.sidebar?.hidden
            ? null
            : (
              <Sidebar
                categoryOptions={categoryOptions}
                category={searchParams.category || null}
                subcategory={searchParams.sub || null}
                onCategoryChange={(value, subvalue) => {
                  const newSearchParams = { ...searchParams };
                  delete newSearchParams.category;
                  delete newSearchParams.sub;
                  if (value) {
                    newSearchParams.category = value;
                    if (subvalue) {
                      newSearchParams.sub = subvalue;
                    }
                  }
                  history.push({ search: stringifyQuery(newSearchParams) });
                }}
                collectionOptions={collectionOptions}
                collection={searchParams.collection || null}
                onCollectionChange={(value) => {
                  const newSearchParams = { ...searchParams };
                  delete newSearchParams.collection;
                  if (value) {
                    newSearchParams.collection = value;
                  }
                  history.push({ search: stringifyQuery(newSearchParams) });
                }}
                sortOptions={sortOptions}
                sort={searchParams.sort || shopDefaultSort}
                onSortChange={(value) => {
                  const newSearchParams = { ...searchParams };
                  delete newSearchParams.sort;
                  if (value && value !== shopDefaultSort) {
                    newSearchParams.sort = value;
                  }
                  history.push({ search: stringifyQuery(newSearchParams) });
                }}
                onSearchChange={setSearchDebounced}
                search={searchDebounced.value}
              />
            )
        }
        <ApiBoundInfiniteScrollQuery
          url="products"
          limit={12}
          renderItem={renderItem}
          getQueryParams={getProductsInfiniteScrollQeuryParams}
          shouldReload={shouldProductsInfiniteQueryReload}
          element={Grid}
          storeType={store.type}
          storeId={store._id}
          categoryId={
            searchParams.category
              ? (
                categoryOptionsSlugIdMap[searchParams.category]
                || null
              )
              : undefined
          }
          subcategoryId={
            searchParams.category && searchParams.sub
              ? (
                (
                  categoryOptionsSlugSubcategoryIdMap
                  ?.[searchParams.category]
                  ?.[searchParams.sub]
                )
                || null
              )
              : undefined
          }
          collectionId={
            searchParams.collection
              ? (
                collectionOptionsSlugIdMap[searchParams.collection]
                || null
              )
              : undefined
          }
          sort={sortValue}
          search={searchDebounced.normalized}
          extractData={extractDataFromInfiniteScrollQuery}
          renderEmpty={() => renderEmpty({
            title: filtersSearchEmptyTitle,
            text: filtersSearchEmptyText,
          })}
        />
      </Items>
    </Wrapper>
  );
}
