/* WIP */
import React, { useEffect, useCallback, useMemo, useState } from 'react';
// import { isEqual } from 'lodash';

import { Edit } from 'react-feather';
import Switch from 'react-ios-switch';

import { useStorePage } from '../context/Store';
import { useApiRequest } from '../context/Api';

import { createNamedStyled } from '../stitches.config';
import { useTheme } from '../theme';

import { Label, Paragraph } from '../components/Elements/Text';

const styled = createNamedStyled('EditModeUI');

const Wrapper = styled.named('Wrapper')('div', {
  position: 'fixed',
  left: 0,
  right: 0,
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  background: 'hsla(0, 0%, 20%, 0.8)',
  backdropFilter: 'blur(5px) saturate(180%)',
  zIndex: 999999999999999,
  bottom: 0,
  boxShadow: '0 0 20px rgba(0, 0, 0, 0.2)',
  padding: 'calc($m / 1.5)',
  transform: 'translateY(100%)',
  transition: 'transform 0.6s cubic-bezier(0.33, 1, 0.68, 1)',

  '*': { color: 'white !important' },

  variants: {
    isActive: {
      true: {
        transform: 'translateY(0%)',
      },
    },
  },
});

const bounceBackTransition = 'cubic-bezier(0.33, 1, 0.68, 1)';

const Handle = styled.named('Handle')('div', {
  position: 'fixed',
  bottom: '10px',
  height: '3px',
  width: '120px',
  borderRadius: '10px',
  background: 'rgba(255, 255, 255, 0.2)',
  marginLeft: '-60px',
  left: '50%',
  mixBlendMode: 'difference',
  backdropFilter: 'blur(20px) saturate(0)',
  zIndex: '999999999999999',
  transition: `transform 0.6s ${bounceBackTransition}`,
  pointerEvents: 'none',

  variants: {
    isActive: {
      true: {
        transform: 'translateY(35px)',
      },
    },
  },
});

const HandleClick = styled.named('HandleClick')('div', {
  cursor: 'pointer',
  position: 'fixed',
  bottom: '0px',
  width: '140px',
  height: '35px',
  marginLeft: '-70px',
  left: '50%',
  zIndex: '999999999999999',

  transition: `transform 0.6s ${bounceBackTransition}`,

  variants: {
    isActive: {
      true: {
        transform: 'translateY(35px)',
      },
    },
  },
});

const Column = styled.named('Column')('div', {
  display: 'flex',
  flexDirection: 'column',
});

const Row = styled.named('Row')('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '$s',

  flex: '0 1 auto',
  width: '75%',
});

const useEditMode = (pageId, pageContent) => {
  const accessTokenRaw = localStorage.getItem('ACCESS_TOKEN');
  const accessToken = useMemo(
    () => {
      try {
        return JSON.parse(accessTokenRaw);
      } catch (error) {
        return '';
      }
    },
    [accessTokenRaw],
  );
  const [{
    data: user,
    // loading,
  }] = useApiRequest({
    url: `${
      window.location.origin
    }/api/users/me?access_token=${
      accessToken
    }`,
  });

  const [isActive, setIsActive] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [freshPageContent, setFreshPageContent] = useState(pageContent);

  const [freshPageUpdateIndex, setFreshPageUpdateIndex] = useState(0);

  const [{
    page: freshPage,
    loading: freshPageLoading,
  }] = useStorePage(
    { _id: pageId },
    freshPageUpdateIndex === 0,
    freshPageUpdateIndex,
  );

  // useEffect(
  //   () => {
  //     if (user?.role === 'ADMIN') {
  //       setIsActive(true);
  //     }
  //   },
  //   [user],
  // );

  useEffect(
    () => {
      if (freshPage?.content) {
        setFreshPageContent(freshPage.content);
      }
    },
    [freshPage],
  );

  useEffect(
    () => {
      let timeout;

      if (isEditMode && !freshPageLoading) {
        timeout = setTimeout(
          (updateIndex) => {
            setFreshPageUpdateIndex(updateIndex + 1);
          },
          1000,
          freshPageUpdateIndex,
        );
      }

      return () => {
        clearTimeout(timeout);
      };
    },
    [freshPageLoading, freshPage, freshPageUpdateIndex, isEditMode, pageId],
  );

  const toggleIsEditMode = useCallback(
    value => (
      typeof value === 'boolean'
      ? setIsEditMode(value)
      : setIsEditMode(!isEditMode)
    ),
    [isEditMode],
  );

  return {
    pageContent: freshPageContent,
    user,
    isActive,
    setIsActive,
    isEditMode,
    setIsEditMode,
    toggleIsEditMode,
  };
};

const EditModeUI = ({
  pageId,
  user,
  isActive,
  setIsActive,
  isEditMode,
  toggleIsEditMode,
}) => {
  const { colors: { brandColor } } = useTheme();

  return (
    user?.role === 'ADMIN'
    ? (
        <>
          <Wrapper isActive={isActive}>
            <Row onClick={() => setIsActive(false)}>
              <a
                href={`/admin/website/pages/${pageId}`}
                target="_blank"
                rel="noreferrer"
              >
                <Edit size={20} color="white" />
              </a>
              <Column>
                <Label css={{ marginBottom: 4 }}>EDIT MODE</Label>
                <Paragraph
                  css={{ fontSize: 12, lineHeight: '130%', opacity: 0.7 }}
                >
                  When active, the page will automatically reload
                  new builder content for the logged in users.
                </Paragraph>
              </Column>
            </Row>
            <Switch
              checked={isEditMode}
              className="switch"
              onChange={toggleIsEditMode}
              onColor={brandColor}
            />
          </Wrapper>
          <Handle
            isActive={isActive}
            onClick={() => setIsActive(!isActive)}
          />
          <HandleClick onClick={() => setIsActive(!isActive)} />
        </>
      )
    : null
  );
};

export { EditModeUI, useEditMode };
