import React, { useState, useEffect, useCallback } from 'react';
import { ChevronLeft, ChevronRight } from 'react-feather';
import useEmblaCarousel from 'embla-carousel-react';
import emblaAutoPlay from 'embla-carousel-autoplay';
import { WheelGesturesPlugin } from 'embla-carousel-wheel-gestures';
import useMedia from 'use-media';
import PropTypes from 'prop-types';

import InnerImageZoom from 'react-inner-image-zoom';
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.css';

import {
  Gallery as PhotoSwipeGallery,
  Item as PhotoSwipeItem,
} from 'react-photoswipe-gallery'
import 'photoswipe/dist/photoswipe.css'

import { createNamedStyled, globalCss } from '../../stitches.config';
import { useTheme } from '../../theme';

const styled = createNamedStyled('Gallery');

const GlobalStyle = globalCss({
  // '.pswp__img': {
  //   borderRadius: '$l',
  //   transition: 'border-radius 300ms',
  // },
});

const Root = styled.named('Root')('div', {});

const PreWrapper = styled.named('Root')('div', {
  position: 'relative',
  width: '100%',
});

const Wrapper = styled.named('Wrapper')('div', {
  position: 'relative',
  overflow: 'hidden',
  // eslint-disable-next-line max-len
  maskImage: 'url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC")',

  variants: {
    overflow: {
      hidden: {
        borderRadius: '$l',
        overflow: 'hidden',
        marginInlineStart: 0,
        padding: 0,
      },
      visible: {
        '@desktop+': {
          borderRadius: '$l',
          padding: '0 calc($m * 1.25)',
          marginInlineStart: 'calc($m * -1.25)',
        },

        '@desktop-': {
          margin: '0 -$s',
          padding: '0 $s',
        },

        '@mobile': {
          padding: '0 $m 0 $s',
        },
      },
      faded: {
        '@desktop+': {
          padding: '0 $l',
          marginInlineStart: '-$l',
        },

        '@desktop-': {
          margin: '0 -$s',
          padding: '0 $s',
        },

        '@mobile': {
          padding: '0 $m 0 $s',
        },

        maskImage: [
          'linear-gradient(to right',
          'transparent 0%',
          'black 7.5%',
          'black 92.5%',
          'transparent 100%)',
        ].join(', '),
      },
    },
  },

  '@tablet': {
    overflow: 'visible !important',
    borderRadius: '0 !important',
    margin: '0 -$s !important',
    padding: '0 $s !important',
    marginInlineStart: 'calc($m * -1.25)',
  },
});

const Container = styled.named('Container')('div', {
  display: 'flex',
  height: '100%',

  cursor: 'grab',
  '&:active': { cursor: 'grabbing' },
});

const Slide = styled.named('Slide')('div', {
  display: 'flex',
  position: 'relative',
  flex: '0 0 100%',
  overflow: 'hidden',
  pointerEvents: 'none',

  aspectRatio: '$style$imageAspectRatio',

  '& > div': {
    '& > *': { position: 'absolute' },
  },

  '@desktop': {
    '&:after': {
      content: '',
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      background: '$accent',
      transition: 'opacity 300ms',
      opacity: 0,
    },
  },

  variants: {
    clickable: {
      true: {
        cursor: 'pointer',
        '&:hover:after': { opacity: 0.5 },
      },
    },

    overflow: {
      visible: {
        marginInlineEnd: '$s',
        borderRadius: '$l',
      },
      faded: {
        marginInlineEnd: '$s',
        borderRadius: '$l',
      },
      hidden: {
        marginInlineEnd: 0,
        borderRadius: 0,
      },
    },

    visible: {
      true: {
        opacity: 1,
        transition: 'opacity 500ms',
      },
      false: {
        opacity: 0,
        transition: 'opacity 500ms',
      },
    },
  },

  '@tablet': {
    flex: '0 0 45%',
    marginInlineEnd: '$s !important',
    borderRadius: '$l !important',
  },

  '.iiz': {
    top: 0,

    width: '100%',
    height: '100%',

    '& > div': {
      height: '100%',
      objectFit: '$style$imageSizing',
    },
  },

  '.gallery-item': {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,

    width: '100%',
    height: '100%',
    objectFit: '$style$imageSizing',
  },

  height: '100%',
  objectFit: '$style$imageSizing',

  '.iiz__img': {
    width: '100%',
    height: '100%',
    objectFit: '$style$imageSizing',
    objectPosition: 'center',
  },

  // borderRadius: '$l',
  // eslint-disable-next-line max-len
  maskImage: 'url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC")',

  '&:first-child': {
    borderTopLeftRadius: '$l',
    borderBottomLeftRadius: '$l',
  },

  '&:last-child': {
    borderTopRightRadius: '$l',
    borderBottomRightRadius: '$l',
  },
});

const Shade = styled.named('Shade')('div', {
  position: 'absolute',
  width: '100%',
  height: '100%',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  background: '$cover',
  opacity: 0.5,
  pointerEvents: 'none',
});

const Video = styled.named('Video')('video', {
  position: 'absolute',
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  objectFit: 'cover',
  objectPosition: 'center',
});

const Controls = styled.named('Controls')('div', {
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: -25,
  display: 'flex',
  justifyContent: 'space-between',
  pointerEvents: 'none',

  variants: {
    disabled: {
      true: {
        display: 'none',
      },
    },
  },
});

const Control = styled.named('Control')('div', {
  width: '5%',
  height: '100%',
  cursor: 'pointer',
  pointerEvents: 'all',
});

const Arrows = styled.named('Arrows')('div', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  position: 'absolute',
  top: 0,
  right: 0,
  left: 0,
  aspectRatio: '$style$imageAspectRatio',
  pointerEvents: 'none',

  '@desktop+': {
    width: 'calc(100% - $l)',
  },
  '@desktop-': {
    width: 'calc(100% - $l)',
  },
  '@mobile': {
    width: 'calc(100% - $s)',
  },

  '@tablet': {
    display: 'none',
  },

  variants: {
    overflow: {
      hidden: {
        width: '100%',
      },
    },
    visible: {
      true: {
        opacity: 1,
        transition: 'opacity 500ms 300ms',
      },
      false: {
        opacity: 0,
        transition: 'opacity 500ms',
      },
    },
  },
});

const Arrow = styled.named('ArrowLeft')('div', {
  width: 32,
  margin: 10,
  color: '$light',
  cursor: 'pointer',
  pointerEvents: 'all',
});

const ThumbsWrapper = styled.named('ThumbsWrapper')(Wrapper, {
  display: 'flex',
  position: 'relative',
  marginTop: '$xs !important',
});

const ThumbsContainer = styled.named('ThumbsContainer')('div', {
  display: 'flex',
  width: 'fit-content',
});

const Thumb = styled.named('Thumb')('div', {
  flex: '0 0 90px',
  position: 'relative',
  overflow: 'hidden',
  borderRadius: '$m',
  cursor: 'pointer',
  marginInlineEnd: '$xs',

  opacity: 0.25,
  transition: 'opacity 300ms',

  '&:hover': {
    opacity: 0.5,
  },

  variants: {
    active: {
      true: {
        opacity: '1 !important',
      },
    },
  },
});

const ThumbImage = styled.named('ThumbImage')('img', {
  width: '100%',
  aspectRatio: '1/1',
  objectFit: 'cover',
  borderRadius: '$m',
  pointerEvents: 'none',
});

const DotsWrapper = styled.named('DotsWrapper')('div', {
  display: 'flex',
  position: 'relative',
  margin: '$s 0',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '$xs',

  '@desktop+': {
    width: 'calc(100% - $l)',
  },
  '@desktop-': {
    width: 'calc(100% - $l)',
  },
  '@mobile': {
    width: 'calc(100% - $s)',
  },

  '@tablet': {
    display: 'none',
  },

  variants: {
    overflow: {
      hidden: {
        width: '100%',
      },
    },
    overlaid: {
      true: {
        position: 'absolute',
        transform: 'translateY(-500%)',
      },
    },
    visible: {
      true: {
        opacity: 1,
      },
      false: {
        opacity: 0,
      },
    },
  },
});

const Dot = styled.named('Dot')('div', {
  flex: '0 0 12px',
  height: '12px',
  borderRadius: '50%',
  cursor: 'pointer',
  pointerEvents: 'all',

  background: '$brandColor',
  opacity: 0.25,
  transition: 'opacity 300ms',

  '&:hover': {
    opacity: 0.5,
  },

  variants: {
    active: {
      true: {
        opacity: '1 !important',
      },
    },

    overlaid: {
      true: {
        background: '$light',
      },
    },
  },
});

const Gallery = ({
  items,
  autoplay,
  autoplayDuration,
  wheelGestures,
  // loop,
}) => {
  const {
    language,
    custom: { parallax },
    gallery: {
      thumbnails,
      dotNavigation,
      arrowsNavigation,
      overflow,
      zoom,
      fullscreen,
    },
  } = useTheme();

  const isSmall = useMedia({ maxWidth: 1024 });
  const isMobile = useMedia({ maxWidth: 768 });

  const [selectedIndex, setSelectedIndex] = useState(0)

  const [isControlAvailable, setIsControlAvailable] = useState(true);
  const [isGhostControlAvailable, setIsGhostControlAvailable] = useState(false);

  const isTablet = useMedia({
    minWidth: 768,
    maxWidth: 1024,
  });

  const [parallaxValues, setParallaxValues] = useState([]);

  const multipleItems = items && (items.length > 1);

  const plugins = [];

  const autoplayPlugin = emblaAutoPlay({
    delay: (autoplayDuration * 1000),
    stopOnInteraction: false,
    stopOnMouseEnter: true,
  });

  if (autoplay) {
    plugins.push(autoplayPlugin);
  }

  const wheelGesturesPlugin = WheelGesturesPlugin();

  if (wheelGestures) {
    plugins.push(wheelGesturesPlugin);
  }

  const [viewportRef, embla] = useEmblaCarousel(
    {
      // loop: loop && !overflow, // loop works good with overflow hidden only
      draggable: multipleItems,
      skipSnaps: true,
      align: 0,
      direction: language.direction,
      containScroll: 'keepSnaps',
    },
    plugins
  );

  const [thumbsRef, emblaThumbs] = useEmblaCarousel({
    containScroll: 'keepSnaps',
    dragFree: false,
    direction: language.direction,
  })

  const scrollPrev = useCallback(() => embla && embla.scrollPrev(), [embla]);
  const scrollNext = useCallback(() => embla && embla.scrollNext(), [embla]);

  const onScrollTo = (index) => {
    if (!embla) return;
    if (index > embla.selectedScrollSnap() && embla.clickAllowed()) {
      scrollNext();
      setIsGhostControlAvailable(true);
    } else if (index < embla.selectedScrollSnap() && embla.clickAllowed()) {
      scrollPrev();
      setIsGhostControlAvailable(true);
    }
  };

  const onStart = () => {
    setIsControlAvailable(false);

    setTimeout(() => {
      setIsControlAvailable(true);
      setIsGhostControlAvailable(false);
    }, 100);
  };

  const onSettle = () => {
    setIsGhostControlAvailable(false);
    setIsControlAvailable(true);
  };

  const isSlideClickable = (index) => {
    if (!embla) return false;
    if (index === embla.selectedScrollSnap()) { return false; }
    return true;
  };

  const onScroll = useCallback(() => {
    if (!embla) return;

    const engine = embla.internalEngine();
    const scrollProgress = embla.scrollProgress();

    const styles = embla.scrollSnapList().map((scrollSnap, index) => {
      let diffToTarget = scrollSnap - scrollProgress;

      if (engine.options.loop) {
        engine.slideLooper.loopPoints.forEach((loopItem) => {
          const target = loopItem.getTarget();
          if (index === loopItem.index && target !== 0) {
            const sign = Math.sign(target);
            if (sign === -1) diffToTarget = scrollSnap - (1 + scrollProgress);
            if (sign === 1) diffToTarget = scrollSnap + (1 - scrollProgress);
          }
        });
      }

      return diffToTarget * (-1 / parallax.gallery) * 100;
    });

    setParallaxValues(styles);
  }, [embla, parallax]);

  useEffect(() => {
    if (!embla) return;

    onScroll();

    embla.on('scroll', onScroll);
    embla.on('resize', onScroll);

    embla.on('pointerDown', onStart);
    embla.on('settle', onSettle);
  }, [embla, onScroll, parallax]);

  useEffect(() => {
    if (!embla) return;
    embla.reInit();
    embla.scrollTo(0);
  }, [embla, items]);

  const onThumbClick = useCallback(
    (index) => {
      if (!embla || !emblaThumbs) return
      embla.scrollTo(index)
    },
    [embla, emblaThumbs],
  )

  const onDotClick = useCallback(
    (index) => {
      if (!embla) return
      embla.scrollTo(index)
    },
    [embla],
  )

  const onSelect = useCallback(() => {
    if (!embla) return
    setSelectedIndex(embla.selectedScrollSnap())
    if (!emblaThumbs) return
    emblaThumbs.scrollTo(embla.selectedScrollSnap())
  }, [embla, emblaThumbs, setSelectedIndex])

  useEffect(() => {
    if (!embla) return
    onSelect()
    embla.on('select', onSelect)
    embla.on('reInit', onSelect)
  }, [embla, onSelect])

  const parallaxDirection = language.direction === 'rtl' ? -1 : 1;

  const [isFullscreenOpen, setIsFullscreenOpen] = useState(false)

  useEffect(() => {
    if (!embla) return
    embla.scrollTo(selectedIndex)
  }, [embla, selectedIndex]);

  const onBeforeFullscreen = (pswpInstance) => {
    setIsFullscreenOpen(true);

    pswpInstance.on('close', () => {
      setIsFullscreenOpen(false);
    })

    pswpInstance.on('change', () => {
      const index = pswpInstance.currIndex;
      setSelectedIndex(index);
    })
  }

  return (
    <Root
      as={fullscreen ? PhotoSwipeGallery : 'div'}
      css={!fullscreen ? { width: '100%' } : {}}
      options={{
        // eslint-disable-next-line max-len
        arrowPrevSVG: '<svg width="75" height="75" viewBox="0 0 75 75" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M42 45L34 37L42 29" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>',
        // eslint-disable-next-line max-len
        arrowNextSVG: '<svg width="75" height="75" viewBox="0 0 75 75" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M33 45L41 37L33 29" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>',
        // eslint-disable-next-line max-len
        closeSVG: '<svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M33 17L17 33" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M17 17L33 33" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>',
        // eslint-disable-next-line max-len
        zoomSVG: '<svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M24 32C28.4183 32 32 28.4183 32 24C32 19.5817 28.4183 16 24 16C19.5817 16 16 19.5817 16 24C16 28.4183 19.5817 32 24 32Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M34 34L29.65 29.65" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M24 21V27" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M21 24H27" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>',
        counter: false,
        padding: { top: 40, bottom: 40, left: 0, right: 0 },
      }}
      {...(fullscreen ? { onBeforeOpen: onBeforeFullscreen } : {})}
    >
      <GlobalStyle />
      <PreWrapper>
        <Wrapper
          ref={viewportRef}
          overflow={overflow}
        >
          <Container>
            {items && items.map((item, index) => {
              const video = item?.src?.includes('mp4') ? item : null;

              return (
                <Slide
                  key={item._id}
                  onClick={() => onScrollTo(index)}
                  clickable={isControlAvailable && isSlideClickable(index)}
                  overflow={overflow}
                  visible={!isFullscreenOpen}
                  css={(index === selectedIndex)
                    ? isFullscreenOpen
                      ? { transition: 'opacity 100ms 100ms' }
                      : { transition: 'opacity 0ms 300ms' }
                    : { transition: 'opacity 500ms' }}
                >
                  {
                    video
                    ? (
                      <Video
                        src={video.src}
                        muted
                        loop
                        playsInline
                        autoPlay
                        width={(parallax && multipleItems) ? '120%' : '100%'}
                        height="100%"
                        style={{
                          transform: parallax && (isSmall && !isMobile)
                            ? `translateX(${parallaxValues[index]
                              * parallaxDirection}%)`
                            : null,
                        }}
                        parallax={parallax}
                      />
                    )
                    : (
                      <div
                        style={{
                          width: '100%',
                          transform: ((parallax && multipleItems) && !isTablet)
                            ? `translateX(${parallaxValues[index]
                              * parallaxDirection}%)`
                            : null,
                          pointerEvents: 'initial',
                        }}
                      >
                        {zoom ? (
                          <InnerImageZoom
                            hideHint
                            src={item.src}
                          />
                        ) : fullscreen ? (
                          <PhotoSwipeItem
                            original={item.src}
                            thumbnail={item.src}
                            width={item.meta.width}
                            height={item.meta.height}
                          >
                            {({ ref, open }) => (
                              // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
                              <img
                                ref={ref}
                                className="gallery-item"
                                src={item.src}
                                alt="gallery-item"
                                onClick={open}
                                style={{
                                  pointerEvents: index === selectedIndex
                                    ? 'initial' : 'none',
                                }}
                              />
                            )}
                          </PhotoSwipeItem>
                          ) : (
                            <img src={item.src} alt="gallery-item" />
                          )}
                      </div>
                    )
                  }
                  <Shade />
                </Slide>
              );
            })}
          </Container>
        </Wrapper>
        {multipleItems ? (
          <Controls disabled={!isGhostControlAvailable}>
            <Control onClick={() => scrollPrev()} />
            <Control onClick={() => scrollNext()} />
          </Controls>
        ) : null}
        {(multipleItems && arrowsNavigation) ? (
          <Arrows
            overflow={overflow}
            visible={!isFullscreenOpen}
          >
            <Arrow as={ChevronLeft} onClick={() => scrollPrev()} size={28} />
            <Arrow as={ChevronRight} onClick={() => scrollNext()} size={28} />
          </Arrows>
        ) : null}
        {(multipleItems && dotNavigation) && (
          <DotsWrapper
            overflow={overflow}
            visible={!isFullscreenOpen}
            // overlaid={thumbnails}
            overlaid
          >
            {items && items.map((item, index) => (
              <Dot
                key={item._id}
                active={selectedIndex === index}
                onClick={() => onDotClick(index)}
                // overlaid={thumbnails}
                overlaid
              />
            ))}
          </DotsWrapper>
        )}
        {(multipleItems && thumbnails) && (
          <ThumbsWrapper ref={thumbsRef} overflow={overflow}>
            <ThumbsContainer>
              {items && items.map((item, index) => (
                  <Thumb
                    key={item._id}
                    onClick={() => onThumbClick(index)}
                    active={selectedIndex === index}
                  >
                    <ThumbImage src={item.src} />
                  </Thumb>
              ))}
            </ThumbsContainer>
          </ThumbsWrapper>
        )}
      </PreWrapper>
    </Root>
  );
};

Gallery.propTypes = {
  autoplay: PropTypes.bool,
  autoplayDuration: PropTypes.number,
  wheelGestures: PropTypes.bool,
};

Gallery.defaultProps = {
  autoplay: false,
  autoplayDuration: 6,
  wheelGestures: false,
};

export default Gallery;
